import React, { useState } from "react";
import { Accordion, Icon, Divider, } from "semantic-ui-react";

const HowItWorks = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion fluid>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Upload photos of you
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <p>
          We recommend at least 20 photos, with a mix of angles and locations.
          The more variety the better!
        </p>
      </Accordion.Content>
<Divider />
      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Our AI will create a unique model of you
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <p>
          Using the photos you uploaded, our AI will create a model of you so it
          can create new unique photos! Your model will be stored in your
          account for future use.
        </p>
      </Accordion.Content>
      <Divider />
      <Accordion.Title
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Create your AI photos
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <p>
          Tell the AI what type of photo you want and it will create it. You can
          choose different clothes, where you are, lighting, time of day, season
          etc. Every photo you create will be stored in your account.
        </p>
      </Accordion.Content>
      <Divider />
      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Download your AI photos
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <p>
          You can download your photos in high resolution and use them on
          on your website, social media, print them, share with friends!
        </p>
      </Accordion.Content>
      <Divider />
    </Accordion>
  );
};

export default HowItWorks;

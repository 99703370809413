import React,  { useState, useEffect }  from 'react';
import axios from "axios";
import { Container, Header, Icon } from 'semantic-ui-react';
import SiteHeader from './SiteHeader';
import Pricing from './Pricing';

const Subscribe = ({ handleLogout }) => {

  const [token, setToken] = useState(null);
  const [user, setUser] = useState([]);


  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      console.log("token found");
    } else {
      console.log("token not found");
    }
  }, []);

  useEffect(() => {

    const getUser = () => {
      axios
        .get("/api/user", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const user = response.data;
          console.log(user);
          setUser(user);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    
    if (token) {
      getUser();
    }
  }, [token]);






  return (
    <div>
      <SiteHeader handleLogout={handleLogout} token={token} />
      <Container style={{ marginTop: '8em' }}>
        <Header textAlign="centered" as="h1">
          Choose your plan
        </Header>
        <Header textAlign="centered" as="h4">
          <Icon name="check" color="green" />
          Free to cancel at any time
        </Header>
        <Header textAlign="centered" as="h4">
          <Icon name="lock" color="yellow" />
          Secure checkout
        </Header>
        <div>
          <Pricing user={user} type="monthly"/>
        </div>
      </Container>
    </div>
  );
};

export default Subscribe;

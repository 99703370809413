import { Routes, Route } from "react-router-dom";
import Confirmation from "./Confirmation";
import Account from "./Account";
import FallbackPage from "./FallbackPage";
import Train from "./Train";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy"
import Studio from "./Studio"
import Create from "./Create"
import BlogList from './BlogList';
import BlogPost from './BlogPost';
import Landing from './Landing';
import ForgotPassword from "./ForgotPassword"; 
import ResetPassword from "./ResetPassword";
import Subscribe from "./Subscribe";
import ReactGA from 'react-ga';

ReactGA.initialize('AW-11201774499');


function App() {
  const path = window.location.pathname;
  console.log(path);

  return (
    <div className="App">
      <Routes>
        <Route path="/account" element={<Account />} />
        <Route path="/account/login" element={<Account />} />
        <Route path="/account/register" element={<Account />} />
        <Route path="/account/plans" element={<Account />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/create" element={<Create />} />
        <Route path="/train" element={<Train />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/" element={<Landing />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:postName" element={<BlogPost />} />
        <Route path="/:landingName" element={<Landing />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/*" element={<FallbackPage />} />
        

      </Routes>
    </div>
  );
}

export default App;

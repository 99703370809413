import React, { useState, useEffect } from "react";
import axios from "axios";
import "./image-grid.css";
import "./App.css";
import "./Pricing";
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Button,
  Message,
  Icon,
  Embed,
  GridRow,
  GridColumn,
  Segment,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { useNavigate, useParams } from "react-router-dom";
import HowItWorks from "./HowItWorks";
import TestimonialCards from "./TestimonialCards";
import FAQs from "./FAQs";
import { Helmet } from "react-helmet";
import LandingForm from "./LandingForm";
import StarsLogo from "./StarsLogo";

function Home() {
  const [token, setToken] = useState(null);
  const [homepageImages, setHomepageImages] = useState([]);
  const navigate = useNavigate();
  const [landingPage, setLandingPage] = useState(null);
  const { landingName } = useParams();
  const landingNameOrHome = landingName || "Home";
  const [userCountry, setUserCountry] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [user, setUser] = useState(null);

  console.log("landingNameOrHome", landingNameOrHome);

  const scrollToSection = (sectionId, scrollOffset) => {
    const section = document.getElementById(sectionId);
    const topPosition =
      section.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: topPosition - scrollOffset, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get("/api/user-country");
        const { country, currency } = response.data;
        setUserCountry(country);
        setCurrency(currency);
      } catch (error) {
        console.error("Error retrieving user country:", error);
      }
    };

    fetchUserCountry();
  }, []);

  useEffect(() => {
    console.log("userCountry", userCountry);
  }, [userCountry]);

  useEffect(() => {
    console.log("currency", currency);
  }, [currency]);

  /*   useEffect(() => {
    fetchData();
  }, []); */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/landing.json");

        const data = await response.json();
        console.log("landing response", data);
        setLandingPage(data.find((page) => page.url === landingNameOrHome));
      } catch (error) {
        console.error("Error fetching landing page data:", error);
      }
    };

    fetchData();
  }, [landingName, landingNameOrHome]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const getUser = () => {
      axios
        .get("/api/user", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const user = response.data;
          console.log("user:", user);
          setUser(user);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (token) {
      getUser();
    }
  }, [token]);

  useEffect(() => {
    if (landingPage) {
      axios
        .get("/api/homepage-images", {
          params: {
            gallery: landingPage.gallery.images,
          },
        })
        .then((response) => {
          setHomepageImages(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [landingPage]);

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setUser(null);
  };

  console.log("url", landingName);

  const canonicalUrl = "https://photogen.io/" + landingName;

  let price = "$19.99";
  switch (currency) {
    case "GBP":
      price = "£15.99";
      break;
    case "EUR":
      price = "€18.99";
      break;
    case "USD":
      price = "$19.99";
      break;
    default:
      price = "$19.99";
      break;
  }

  /*   let offerPrice = "$1.99";
  switch (currency) {
    case "GBP":
      offerPrice = "£1.59";
      break;
    case "EUR":
      offerPrice = "€1.89";
      break;
    case "USD":
      offerPrice = "$1.99";
      break;
    default:
      offerPrice = "$1.99";
      break;
  } */

  if (landingPage) {
    return (
      <div>
        <SiteHeader
          handleLogout={handleLogout}
          token={token}
          parent={"home"}
          user={user}
        />
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>{landingPage.pagetitle}</title>
          <meta name="description" content={landingPage.pagemeta} />
        </Helmet>
        <div
          style={{
            paddingTop: "5em",
            paddingBottom: "0em",
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('siteimages/bg.avif')",
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        >
          <Container
            /* className="custom-container" */ style={{ marginTop: "1em" }}
          >
            {user && (
              <>
                {user.available_image_generations === 0 &&
                user.available_model_trainings === 0 &&
                user.stripe_subscription_status === "demo" ? (
                  <Message info icon>
                    <Icon name="clock outline" />
                    <Message.Content>
                      <Header>Sorry, your demo access has expired</Header>
                      <div style={{ marginTop: "1em" }}>
                        <p>No worries, it's simple to continue!</p>
                        <p>
                          Choose from one of our subscriptions to train a model
                          of yourself and create more photos.
                        </p>

                        <div>
                          <Button
                            size="medium"
                            color="blue"
                            onClick={() => navigate("/subscribe")}
                          >
                            Upgrade to create more photos
                          </Button>
                        </div>
                      </div>
                    </Message.Content>
                  </Message>
                ) : (
                  <Message info>
                    <Header>Welcome back {user.email}</Header>
                    <div style={{ marginTop: "1em" }}>
                      <div>
                        You can train {user.available_model_trainings} more
                        models
                      </div>
                      <div>
                        You can make {user.available_image_generations} more
                        photos
                      </div>
                      <div style={{ paddingTop: "1em" }}>
                        <Button
                          size="medium"
                          color="blue"
                          onClick={() => navigate("/studio")}
                        >
                          Go to studio
                        </Button>
                      </div>
                    </div>
                  </Message>
                )}
              </>
            )}

            <div /* className="custom-content" */>
              <div
                style={{
                  fontSize: "1.4em",
                  textAlign: "left",
                  textWrap: "balance",
                }}
                className="vertical-align"
              >
                <div>
                  <div>
                    <Header
                      as="h1"
                      size="huge"
                      className="mainheader textShadow"
                      style={{
                        color: "white",
                      }}
                    >
                      {landingPage.hero.h1}{" "}
                      {/*  <Image src="stars.svg" style={{width: "1.5em", top: "-5px", left: "-15px"}}/> */}
                    </Header>
                    <Header
                      as="h2"
                      size="small"
                      className="header2 textShadow"
                      style={{
                        color: "white",
                      }}
                    >
                      {landingPage.hero.h2}
                    </Header>
                    <Header
                      as="h3"
                      size="tiny"
                      className="header3 textShadow"
                      style={{
                        color: "white",
                      }}
                    >
                      {landingPage.hero.h3}
                    </Header>
                  </div>
                  <div style={{ marginTop: "1em" }}>
                    {token ? (
                      <Button
                        onClick={() => navigate("/studio")}
                        color="blue"
                        size="massive"
                        style={{ marginBottom: "1em" }}
                      >
                        Go to studio
                        <Icon name="arrow right" />
                      </Button>
                    ) : (
                      <div>
                        <Grid>
                          <GridRow>
                            <GridColumn
                              mobile={16}
                              tablet={8}
                              computer={8}
                              verticalAlign="middle"
                              style={{ marginBottom: "0.5em" }}
                            >
                              <div align="left">
                                <Button
                                  size="massive"
                                  color="orange"
                                  onClick={() => navigate("/train")}
                                >
                                  <div className="textShadow">
                                    Make your photos now
                                  </div>
                                </Button>
                              </div>
                            </GridColumn>
                            <GridColumn
                              mobile={16}
                              tablet={8}
                              computer={8}
                              verticalAlign="top"
                              style={{ marginBottom: "0.5em" }}
                            >
                              {/* <div align="left">
                                <LandingForm />
                              </div> */}
                            </GridColumn>
                          </GridRow>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div>
          <Container
            style={{
              marginTop: "0em",
              paddingTop: "1.5em",
              paddingBottom: "3em",
            }}
            id="pricing"
          >
            <Grid>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={7}
                  computer={7}
                  verticalAlign="middle"
                >
                  <Header
                    size="small"
                    style={{
                      textWrap: "balance",
                      color: "#51648b",
                    }}
                  >
                    Upload photos
                  </Header>
                </GridColumn>
                <GridColumn mobile={2} tablet={2} computer={2}></GridColumn>

                <GridColumn
                  mobile={7}
                  tablet={7}
                  computer={7}
                  verticalAlign="middle"
                >
                  <Header
                    size="small"
                    style={{
                      textWrap: "balance",
                      color: "#51648b",
                    }}
                  >
                    {/*  <Icon name="heart" color="red"></Icon> */}
                    Make thousands like this with AI
                  </Header>
                </GridColumn>
              </GridRow>

              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={7}
                  computer={7}
                  verticalAlign="middle"
                >
                  <Grid>
                    <GridRow>
                      <GridColumn mobile={8} tablet={8} computer={8}>
                        <Image
                          src={"siteimages/bad1.avif"}
                          rounded
                          className="desaturate"
                        />
                      </GridColumn>
                      <GridColumn mobile={8} tablet={8} computer={8}>
                        <Image
                          src={"siteimages/bad2.avif"}
                          rounded
                          className="desaturate"
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn mobile={8} tablet={8} computer={8}>
                        <Image
                          src={"siteimages/bad3.avif"}
                          rounded
                          className="desaturate"
                        />
                      </GridColumn>
                      <GridColumn mobile={8} tablet={8} computer={8}>
                        <Image
                          src={"siteimages/bad4.avif"}
                          rounded
                          className="desaturate"
                        />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </GridColumn>
                <GridColumn
                  mobile={2}
                  tablet={2}
                  computer={2}
                  verticalAlign="middle"
                  style={{ overflow: "visible" }}
                >
                  <div
                    style={{
                      width: "150%",
                      height: "auto",
                      zIndex: "1",
                      marginRight: "0px",
                      marginLeft: "-20px",
                      transform: "rotate(-25deg)",
                      objectFit: "cover",
                      position: "absolute",
                      top: "-20px",
                    }}
                  >
                    <Image src={"siteimages/curly.png"} />
                  </div>
                </GridColumn>

                <GridColumn
                  mobile={7}
                  tablet={7}
                  computer={7}
                  verticalAlign="middle"
                >
                  <div style={{ zIndex: "100" }}>
                    <Image src={"siteimages/nice.jpeg"} rounded />
                  </div>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn mobile={0} tablet={9} computer={9}></GridColumn>

                <GridColumn mobile={16} tablet={7} computer={7}>
                  <div>
                    <LandingForm />
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </Container>
        </div>

        <div className="alt-background-color3">
          <Container
            style={{
              marginTop: "0em",
              paddingTop: "3em",
              paddingBottom: "3em",
            }}
            id="pricing"
          >
            <Grid stackable divided>
              <Grid.Row verticalAlign="top">
                {/* <Grid.Column width={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <Header
                      size="large"
                      textAlign="left"
                      style={{ color: "#51648b" }}
                    >
                      How it works
                    </Header>
                    <List ordered relaxed size="large" className="bold-list">
                      <List.Item className="bold-item">
                        Upload 10-15 photos
                      </List.Item>
                      <List.Item className="bold-item">
                        Choose from different styles, shots, locations, and
                        outfits
                      </List.Item>
                      <List.Item className="bold-item">
                        Generate your photos
                      </List.Item>
                      <List.Item className="bold-item">
                        Download 4K photos to use anywhere!
                      </List.Item>
                    </List>
                  </div>
                </div>
              </Grid.Column> */}
                <Grid.Column width={8}>
                  {" "}
                  <Header
                    size="large"
                    style={{
                      textWrap: "balance",
                      color: "#51648b",
                    }}
                  >
                    <span>{landingPage.pricing.header}</span>
                  </Header>
                  <div>
                    <Header size="huge" textAlign="left">
                      <span style={{ fontSize: "0.5em" }}>FROM</span>{" "}
                      <span>{price} </span>
                      <span style={{ fontSize: "0.5em" }}>/ mo</span>
                    </Header>
                    <div style={{ textAlign: "left", marginBottom: "0.5em" }}>
                      <Icon name="check" color="green"></Icon>Cancel for free at
                      any time
                    </div>
                  </div>
                  {!token && (
                    <p style={{ textAlign: "left" }}>
                      <a
                        href=""
                        onClick={(event) => {
                          event.preventDefault(); // Prevent the default behavior
                          navigate("/account"); // Handle the navigation manually
                        }}
                      >
                        <Icon name="arrow right" color="grey"></Icon> See all
                        plans
                      </a>
                      {/*     <Button
                      size="huge"
                      color="blue"
                      onClick={() => navigate("/account")}
                    >
                      Buy Now
                    </Button> */}
                    </p>
                  )}
                </Grid.Column>
                <Grid.Column width={8}>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <Header
                        size="large"
                        style={{ color: "#51648b", textWrap: "balance" }}
                      >
                        What's included
                      </Header>
                      {/*    <div style={{ fontSize: "0.8em", marginTop: "-15px" }}>
                      (with montly subscription)
                    </div> */}
                      <List className="bold-list" relaxed size="large">
                        <List.Item className="bold-item">
                          <Icon name="check" color="green" />
                          Create 500 photos per month
                        </List.Item>
                        <List.Item className="bold-item">
                          <Icon name="check" color="green" />
                          Create 2 models per month
                        </List.Item>
                        <List.Item className="bold-item">
                          <Icon name="check" color="green" />
                          Download stunning 4K photos
                        </List.Item>
                        <List.Item className="bold-item">
                          <Icon name="check" color="green" />
                          You own the copyright
                        </List.Item>
                      </List>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
        {/*     Video demos */}
        <div
          id="video"
          className="alt-background-color2"
          style={{ textWrap: "balance" }}
        >
          <Container textAlign="center" style={{ paddingTop: "1em" }}>
            <Header
              size="huge"
              textAlign="left"
              style={{ color: "#51648b", textWrap: "balance" }}
            >
              How it works
            </Header>
            <Grid stackable style={{ paddingTop: "1em", paddingBottom: "3em" }}>
              <GridRow>
                <GridColumn width={8}>
                  <Header size="large" textAlign="left">
                    1. Make your own AI model
                  </Header>
                  <Header size="medium" textAlign="left">
                    <div className="dark-grey">
                      Make new and unique photos by training our AI to know what
                      you look like. Simply upload a minimum of 20 photos in a
                      variety of locations, clothing, times of day and angles.
                      Training takes about 30 minutes, and once done, you can
                      generate thousands of unique photos.
                    </div>
                  </Header>
                </GridColumn>
                <GridColumn width={8}>
                  <div>
                    <div>
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        //controls
                        style={{
                          borderRadius: "10px",
                          maxHeight: "600px",
                          //boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2",
                        }}
                      >
                        <source src="/train_web.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </GridColumn>
              </GridRow>
              <GridRow style={{ paddingTop: "3em" }}>
                <GridColumn width={8} className="grid-column-2">
                  <div>
                    <div>
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        //controls
                        className="video-element"
                        style={{
                          borderRadius: "10px",
                          maxHeight: "600px",
                          //boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2",
                        }}
                      >
                        <source src="/create_web.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </GridColumn>
                <GridColumn width={8} className="grid-column-1">
                  <Header size="large" textAlign="left">
                    2. Make beautiful photos
                  </Header>
                  <Header size="medium" textAlign="left">
                    <div className="dark-grey">
                      Picture yourself on a beach, in the forest, or at the
                      office. Wearing the perfect outfit and looking great with
                      fantastic lighting and photo composition. You can do all
                      that and more with our AI. Create thousands of unique
                      photos to meet your creative vision. It takes around 90
                      seconds per photo - a short time to wait whilst we ensure
                      your photos are highly realistic and in 4K resolution.
                    </div>
                  </Header>
                </GridColumn>
              </GridRow>
            </Grid>
          </Container>
        </div>

        <div style={{ backgroundColor: "#f5f5f7", paddingBottom: "2em" }}>
          <Container id="examples">
            <Header
              size="large"
              textAlign="left"
              style={{
                paddingBottom: "1em",
                paddingTop: "1em",
                textWrap: "balance",
              }}
            >
              {landingPage.gallery.header}
            </Header>
          </Container>

          <Container>
            <Grid centered columns={2}>
              {homepageImages.map((image, index) => (
                <Grid.Column key={index} mobile={8} tablet={4} computer={4}>
                  <Image centered src={image} rounded />
                </Grid.Column>
              ))}
            </Grid>
          </Container>
        </div>
        <div className="alt-background-color2">
          <Container
            id="how-it-works"
            style={{
              marginTop: "0em",
              paddingTop: "2em",
              paddingBottom: "4em",
            }}
          >
            <Header size="large" textAlign="left" style={{ color: "#51648b" }}>
              How it works
            </Header>
            <Header size="medium" textAlign="left">
              <Icon name="check" color="green" />{" "}
              {landingPage.howitworks.header}
            </Header>
            <HowItWorks />
          </Container>
        </div>
        <div
          style={{
            paddingTop: "3em",
            paddingBottom: "0em",
          }}
          id="testimonials"
        >
          <div style={{ textAlign: "center", textWrap: "balance" }}>
            <span
              size="large"
              style={{
                fontSize: "2em",
                fontWeight: "bold",
                textWrap: "balance",
              }}
            >
              <Icon
                name="heart"
                color="red"
                size="small"
                style={{ paddingRight: "1em" }}
              />{" "}
              Our customers are lovin' it
              <Icon
                name="heart"
                color="red"
                size="small"
                style={{ paddingLeft: "0.5em" }}
              />
            </span>
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "2em",
              color: "grey",
              fontSize: "1.2em",
              paddingBottom: "2em",
            }}
          >
            Don't just take our word for it.
          </div>
          <div style={{ paddingTop: "1em", paddingBottom: "3em" }}>
            {console.log(landingPage.testimonials)}
            <TestimonialCards
              landingPageTestimonials={landingPage.testimonials}
            />
          </div>
        </div>

        <div
          style={{
            paddingTop: "3em",
            paddingBottom: "3em",
          }}
          id="faqs"
          className="alt-background-color2"
        >
          <Container>
            <Header size="large" textAlign="left">
              FAQs
            </Header>
            <FAQs />
          </Container>
        </div>
        <div>
          <Container
            style={{
              marginTop: "4em",
              paddingTop: "3em",
              paddingBottom: "3em",
            }}
            id="pricing"
          >
            <Grid stackable>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={6}>
                {" "}
                <Header size="huge" textAlign="center">
                  <Icon name="star outline" color="yellow" size="large"></Icon>
                  {landingPage.pricing.header}
                </Header>
                <div style={{ marginTop: "1em", textAlign: "center" }}>
                  <Button
                    size="massive"
                    color="orange"
                    onClick={() => navigate("/account")}
                  >
                    Make your photos now
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ textAlign: "left" }}>
                    <Header size="small">
                      <Icon name="check" color="green"></Icon>Create 500 photos
                      per month
                    </Header>
                    <Header size="small">
                      <Icon name="check" color="green"></Icon>Create 2 models
                      per month
                    </Header>
                    <Header size="small">
                      <Icon name="check" color="green"></Icon>Download stunning
                      4K photos
                    </Header>
                    <Header size="small">
                      <Icon name="check" color="green"></Icon>You own the
                      copyright
                    </Header>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <Footer parent={"home"} />
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Home;

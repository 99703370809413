import { useState } from "react"; // Import the useState hook if not already imported
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Button,
  Message,
  Icon,
  Embed,
  GridRow,
  GridColumn,
  Segment,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./App.css";

const LandingForm = () => {
  const [selectedModel, setSelectedModel] = useState(
    "6a1cf894-322a-46ea-879a-3872ccf86c1c"
  ); // Initialize state for the selected model
  const navigate = useNavigate();

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value); // Update the selected model state when the select value changes
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (selectedModel) {
      // Do any necessary processing with the selectedModel value before submitting
      console.log("Selected Model:", selectedModel);
      // Here you can make any API calls to /create or handle the form submission as needed
      // For this example, we will navigate to the "/create" endpoint as before
      navigate(`/create?model=${selectedModel}`);
    }
  };

  return (
    <Segment basic style={{border: "0px solid #dedff0", 
    borderRadius: "5px"}}>
    
      <div style={{ width: "100%" }}>
        <Header
          textAlign="left"
          size="medium"
          style={{ color: "#37445d", textWrap: "balance"/* , textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" */ }}
        >
          Check out our demo models
        </Header>
        <form onSubmit={handleSubmit}>
          <div className="centered-container">
            {/* Your select element */}
            <select
              id="model"
              name="model"
              style={{
                marginBottom: "0em",
                marginTop: "0.0em",
                width: "150px",
                borderRadius: "5px",
              }}
              value={selectedModel}
              onChange={handleModelChange}
            >
              <option value="6a1cf894-322a-46ea-879a-3872ccf86c1c">
                Aisha
              </option>
              <option value="dc78c372-debd-4d92-81c7-4c977975c039">
                Sophia
              </option>
            </select>

            {/* Your button */}
            <Button
            className="link-button"
              color="blue"
              size="medium"
              type="submit"
              style={{ marginLeft: "0.4em", backgroundColor: "#abc6ed", color: "#2b4f82"}}
            >
              Take photos
            </Button>
          </div>
        </form>
      </div>
    </Segment>
  );
};

export default LandingForm;

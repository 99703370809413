import * as React from "react";

function PricingPage({ user, type }) {
  // Paste the stripe-pricing-table snippet in your React component

  let tableID;
  const apiKey = process.env.REACT_APP_PUBLISHABLE_KEY;

  if (type === "one-time") {
    tableID = process.env.REACT_APP_ONE_TIME_PRICING_TABLE_ID;
  } 
  else {
    tableID = process.env.REACT_APP_SUBSCRIPTION_PRICING_TABLE_ID;
  }

  console.log("pricing table user", user);

  console.log(tableID);
  return (
    <div>
      <stripe-pricing-table
        pricing-table-id={tableID}
        publishable-key={apiKey}
        client-reference-id={user._id}
        customer-email={user.email}
      ></stripe-pricing-table>
    </div>
  );
}

export default PricingPage;

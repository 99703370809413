import React, { useEffect, useState } from "react";
import axios from "axios";
import { Message, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const ProcessingModels = () => {
  const [processingModels, setProcessingModels] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    if (token) {
      // Function to fetch processing models from the API
      const fetchProcessingModels = async () => {
        try {
          const response = await axios.get("/api/processingModels", {
            headers: {
              Authorization: `${token}`,
            },
          });
          setProcessingModels(response.data);
        } catch (error) {
          console.error("Error fetching processing models:", error);
        }
      };

      fetchProcessingModels();
    }
  }, [token]);

  function getTimeDifference(created_at) {
    const createdTime = new Date(created_at);
    const currentTime = new Date();
    const timeDifference = Math.floor((currentTime - createdTime) / 1000); // Time difference in seconds

    const minutes = Math.floor(timeDifference / 60); // Calculate minutes
    const seconds = timeDifference % 60; // Calculate seconds

    return { minutes, seconds };
  }

  return (
    <React.Fragment>
      {processingModels.length > 0 && (
        <div>
          <Message icon info>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Models processing...</Message.Header>
              <Message.List>
                {processingModels.map((model) => {
                  const { minutes, seconds } = getTimeDifference(
                    model.created_at
                  );
                  const remainingMinutes = 30 - minutes;
                  const isDelayed = minutes > 120; // Check if time difference is greater than 2 hours

                  if (!isDelayed) {
                    return (
                      <div key={model.id}>
                        <Message.Item>
                          Your model "{model.model_name}" has been training for{" "}
                          {minutes} minutes and {seconds} seconds. It will be
                          ready in approximately {remainingMinutes} minutes.
                        </Message.Item>
                      </div>
                    );
                  } else {
                    return (
                      <div key={model.id}>
                        <Message.Item>
                          There is an issue training your model "
                          {model.model_name}". Please contact {" "}
                          <a href="mailto:support@photogen.io">
                            support@photogen.io
                          </a>
                        </Message.Item>
                      </div>
                    );
                  }

                  return null;
                })}
              </Message.List>
            </Message.Content>
          </Message>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProcessingModels;


import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import {
  Container,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

function FallbackPage() {

  


  return (
    // Display loading spinner or other content
   
    <div>
       <SiteHeader />
      <Container style={{paddingTop: "5em"}}>
        <p>Sorry, we can't find the content you're looking for. Please go to <a href="/">home</a></p>
      
      
      
      </Container>
      <Footer />
    </div>
  );
}
export default FallbackPage;
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./image-grid.css";
import "./App.css";
import {
  Container,
  Grid,
  Header,
  Image,
  Form,
  Button,
  Input,
  Message,
  Icon,
  Step,
  Accordion,
  MessageList,
  MessageItem,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { useNavigate } from "react-router-dom";

function Train() {
  const [files, setFiles] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [token, setToken] = useState(null);
  const [modelName, setModelName] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [processingModel, setProcessingModel] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate();
  const [availableTrainings, setAvailableTrainings] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [userModels, setUserModels] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const minimumPhotosNeeded = 10;

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    if (processingModel) {
      setFiles([]);
      setFileData([]);
      handleStepClick(2);
    }
  }, [processingModel]);

  useEffect(() => {
    if (files && files.length !== 0) {
      console.log("fileData: ", fileData);
      fetchAvailableTrainings();
      //handleStepClick(1);
    }
  }, [files]);

  useEffect(() => {
    if (token) {
      getUserModels();

      //loadGeneratedImages();
    }
  }, [token]);

  const getUserModels = () => {
    axios
      .get("/api/user-models", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const models = response.data;
        console.log(models);
        setUserModels(models);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchAvailableTrainings = async () => {
    try {
      const response = await axios.get("/api/check-available-model-trainings", {
        headers: {
          Authorization: `${token}`,
        },
      });

      const { available_model_trainings, customerStatus } = response.data;
      setAvailableTrainings(available_model_trainings);
      setSubscriptionStatus(customerStatus.customerStatus);
      console.log("available_model_trainings: ", response.data);
    } catch (error) {
      console.error("Error while fetching available trainings:", error);
    }
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const files = event.target.files;
    console.log("files:", files);
    setFileCount(files.length);
    const selectedFiles = Array.from(event.target.files);
    console.log("selectedFiles:", selectedFiles);
    setFiles(selectedFiles);
    if (files.length >= minimumPhotosNeeded) {
      handleStepClick(1);
    }

    // Handle the selected file here
  };

  const handleFileSubmit = () => {
    handleStepClick(1);
    /*   const formData = new FormData();
    files.forEach((file) => {
      formData.append("images", file);
    });
    //setFiles([])
    setFileData(Array.from(formData.values())); // Get binary data array from formData */
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleCreateModel = () => {
    setButtonClicked(true);
    const formData = new FormData();
    //console.log(fileData);
    files.forEach((file) => {
      formData.append("images", file);
      console.log(file);
    });
    formData.append("modelName", modelName);
    formData.append("gender", selectedGender);
    for (const entry of formData.entries()) {
      console.log(entry);
    }

    axios
      .post("/api/create-model2", formData, {
        headers: {
          Authorization: `${token}`,
        },
        responseType: "json", // Update the response type to 'json'
      })
      .then((response) => {
        console.log("response:", response);
        const modelData = response.data;
        console.log(modelData); // Log the response data for debugging

        if (response.status === 200) {
          // If the response is successful, continue with the desired logic
          setModelName("");
          setSelectedGender("");
          setProcessingModel(true);
          return modelData;
          // Handle the modelData or perform any other necessary actions
        } else if (response.status === 403) {
          // If the response status is 403, handle the access denied error
          setSubscriptionStatus("lapsed");
          throw new Error("Access denied. Subscription required.");
        } else {
          // Handle other error scenarios
          throw new Error("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCreateModelOnboard = () => {
    setButtonClicked(true);

    const formData = new FormData();
    // Assuming 'files' is defined somewhere in the component
    files.forEach((file) => {
      formData.append("files", file);
      //console.log(file);
    });

    // Display FormData entries for debugging
    for (const entry of formData.entries()) {
      console.log(entry);
    }

    axios
      .post("/api/upload-temp-images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Log uploaded URLs and store them in localStorage
        console.log("Uploaded URLs:", response.data.urls);
        localStorage.setItem(
          "uploadedImages",
          JSON.stringify(response.data.urls)
        );
        localStorage.setItem("modelName", modelName);
        localStorage.setItem("selectedGender", selectedGender);

        // Navigate to another page
        navigate("/account");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  const handleClick = () => {
    setActiveIndex(activeIndex === 0 ? -1 : 0);
  };

  const renderTrainModelContent = () => {
    return (
      <div>
        <div style={{ marginTop: "6em" }}>
          <Step.Group fluid size="large">
            <Step active={activeStep === 0} completed={activeStep > 0}>
              <Step.Content>
                <Step.Title>
                  <Icon name="cloud upload" color="grey"></Icon>
                  <span style={{ paddingLeft: "0.4em" }}>1. Get started</span>
                </Step.Title>
              </Step.Content>
            </Step>
            <Step active={activeStep === 1} completed={activeStep > 1}>
              <Step.Content>
                <Step.Title>
                  <Icon name="cogs" color="grey"></Icon>
                  <span style={{ paddingLeft: "0.4em" }}>
                    2. Train your model
                  </span>
                </Step.Title>
              </Step.Content>
            </Step>
            <Step active={activeStep === 2}>
              <Step.Content>
                <Step.Title>
                  <Icon name="check" color="grey"></Icon>
                  <span style={{ paddingLeft: "0.4em" }}>3. All done!</span>
                </Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>
        <Container style={{ marginTop: "1em", marginBottom: "0.5em" }}>
          {activeStep === 0 && <Header as="h1">Get started</Header>}
          {activeStep === 1 && <Header as="h1">Train your model</Header>}
          {activeStep === 2 && <Header as="h1">Training started</Header>}
        </Container>

        {activeStep === 0 && (
          <div>
            <p>
              {" "}
              It's quick and easy to create amazing AI photos. To get started,
              upload between 15-20 photos.
            </p>
            <Form>
              <Form.Group>
                <Form.Field style={{ marginBottom: "1em" }}>
                  <div
                    className="ui action input"
                    style={{ maxWidth: "160px" }}
                  >
                    <input
                      type="text"
                      placeholder="Select photos"
                      readOnly
                      value={`${fileCount} photos selected`}
                    />
                    <label
                      htmlFor="file"
                      className="ui icon button"
                      style={{
                        backgroundColor: "#2185D0",
                        color: "white",
                        borderRadius: "0%",
                      }}
                    >
                      <i className="image icon" style={{ color: "white" }}></i>
                      <div style={{ paddingLeft: "0.1em" }}>Choose photos</div>
                    </label>
                    <input
                      id="file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                      multiple
                    />
                  </div>
                </Form.Field>
                {/*     {fileCount && fileCount > 0 ? (
                  <Form.Field>
                    <Button
                      size="large"
                      color="blue"
                      onClick={handleFileSubmit}
                    >
                      Upload
                    </Button>
                  </Form.Field>
                ) : (
                  ""
                )} */}
              </Form.Group>
            </Form>
            {files && files.length < minimumPhotosNeeded && files.length > 0 ? (
              <div style={{ color: "Red" }}>
                Not enough photos to create model. Choose more.
              </div>
            ) : (
              ""
            )}
            <Message warning>
              <Message.Header>
                <Icon name="info circle"></Icon> Read this first! To get high
                quality photos, follow these rules:
              </Message.Header>

              <MessageList>
                <MessageItem>No hats or glasses</MessageItem>
                <MessageItem>No duplicate or similar images</MessageItem>
                <MessageItem>Only you in the photos</MessageItem>
                <MessageItem>Use a wide variety of photos</MessageItem>
                <MessageItem>Use a mix of headshots and upper body</MessageItem>
                <MessageItem>
                  Avoid full body shots with lots in the background
                </MessageItem>
              </MessageList>
            </Message>
          </div>
        )}

        {activeStep === 1 && files.length > 0 && (
          <div>
            {files && files.length < minimumPhotosNeeded ? (
              <p style={{ color: "Red" }}>
                Not enough photos to create model. Upload more.
              </p>
            ) : (
              <p style={{ color: "green" }}>
                {files.length} photos successfully uploaded.
              </p>
            )}

            <Accordion fluid>
              <Accordion.Title active={activeIndex === 0} onClick={handleClick}>
                <Icon name="triangle right"></Icon>Show uploaded photos
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <div className="image-grid-train">
                  {files.map((file, index) => (
                    <div key={index}>
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              </Accordion.Content>
            </Accordion>

            <div text style={{ marginTop: "1em" }}>
              <p>
                Next, enter a name for the person in the photos you uploaded and
                select their gender (our AI needs this information to create a
                model).
              </p>
              <Form>
                <Form.Field>
                  <label htmlFor="modelName">Model Name</label>
                  <div style={{ maxWidth: "400px" }}>
                    <Input
                      type="text"
                      id="modelName"
                      value={modelName}
                      onChange={(event) => setModelName(event.target.value)}
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  <label htmlFor="gender">Gender</label>
                  <div style={{ maxWidth: "400px" }}>
                    <select
                      id="gender"
                      value={selectedGender}
                      onChange={(event) =>
                        setSelectedGender(event.target.value)
                      }
                    >
                      <option value="">Select gender</option>
                      <option value="man">Male</option>
                      <option value="woman">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </Form.Field>
                <Form.Field>
                  {token ? (
                    <Button
                      onClick={handleCreateModel}
                      disabled={
                        buttonClicked ||
                        availableTrainings === 0 ||
                        !modelName ||
                        !selectedGender ||
                        (subscriptionStatus !== "subscribed" &&
                          subscriptionStatus !== "one-time" &&
                          subscriptionStatus !== "demo")
                      }
                      color="blue"
                    >
                      {" "}
                      {buttonClicked ? (
                        <div>
                          <Icon name="circle notched" loading></Icon> Please
                          wait (up to 1 minute)
                        </div>
                      ) : (
                        "Create AI model"
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={handleCreateModelOnboard}
                      disabled={buttonClicked || !modelName || !selectedGender}
                      color="blue"
                    >
                      {buttonClicked ? (
                        <div>
                          <Icon name="circle notched" loading></Icon>
                          Please wait (up to 1 minute)
                        </div>
                      ) : (
                        "Next"
                      )}
                    </Button>
                  )}

                  {token &&
                    subscriptionStatus !== "subscribed" &&
                    subscriptionStatus !== "one-time" &&
                    subscriptionStatus !== "demo" && (
                      <Message negative icon>
                        <Icon name="warning sign" />
                        <Message.Content>
                          <Message.Header>Subscription expired</Message.Header>
                          Sorry, your subscription has expired.
                          <div style={{ paddingTop: "1em" }}>
                            <Button
                              primary
                              onClick={(event) => {
                                event.preventDefault(); // Prevent the default behavior
                                navigate("/subscribe"); // Handle the navigation manually
                              }}
                            >
                              Subscribe
                            </Button>
                          </div>
                        </Message.Content>
                      </Message>
                    )}

                  {token &&
                    availableTrainings === 0 &&
                    subscriptionStatus === "one-time" && (
                      <Message icon>
                        <Icon name="warning sign" />
                        <Message.Content>
                          <Message.Header>
                            No more training credit
                          </Message.Header>
                          Sorry, no more training credit available.
                          <div style={{ paddingTop: "1em" }}>
                            <Button
                              primary
                              onClick={(event) => {
                                event.preventDefault(); // Prevent the default behavior
                                navigate("/subscribe"); // Handle the navigation manually
                              }}
                            >
                              Upgrade to train your model
                            </Button>
                          </div>
                        </Message.Content>
                      </Message>
                    )}

                  {token &&
                    availableTrainings === 0 &&
                    subscriptionStatus === "demo" && (
                      <Message icon>
                        <Icon name="info" />
                        <Message.Content>
                          <Message.Header>
                            Training your own model needs a subscription
                          </Message.Header>

                          <div style={{ paddingTop: "1em" }}>
                            <Button
                              color="orange"
                              onClick={(event) => {
                                event.preventDefault(); // Prevent the default behavior
                                navigate("/subscribe"); // Handle the navigation manually
                              }}
                            >
                              Subscribe here
                            </Button>
                          </div>
                        </Message.Content>
                      </Message>
                    )}

                  {token &&
                    availableTrainings === 0 &&
                    subscriptionStatus === "subscribed" && (
                      <Message icon>
                        <Icon name="warning sign" />
                        <Message.Content>
                          <Message.Header>
                            No more training credit
                          </Message.Header>
                          Sorry, no more training credit available this month.
                          Contact us if you want to add more credits.
                        </Message.Content>
                      </Message>
                    )}

                  {processingModel ? (
                    <Message icon info>
                      <Icon name="circle notched" loading />
                      <Message.Content>
                        <Message.Header>Model processing</Message.Header>
                        Please wait up to 30 minutes for your model to be
                        created
                      </Message.Content>
                    </Message>
                  ) : (
                    ""
                  )}
                </Form.Field>
              </Form>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div>
            <Icon name="check" color="green"></Icon>All done! Your model is now
            training and will take approximately 30 minutes to complete.{" "}
            <Message icon positive>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>
                  Hold tight, we're training your model
                </Message.Header>
                <p>
                  <input
                    value="Check progress"
                    type="button"
                    className="link-button"
                    onClick={() => navigate("/create")}
                  />{" "}
                  on your model
                </p>
              </Message.Content>
            </Message>
          </div>
        )}
      </div>
    );
  };

  /*   if (!token) {
    return <div>not logged in</div>;
  } else { */
  return (
    <div>
      <SiteHeader
        handleLogout={handleLogout}
        token={token}
        parent={"studio"}
        models={userModels}
      />

      <Container style={{ marginTop: "6em" }}>
        {token &&
          availableTrainings === 0 &&
          subscriptionStatus === "one-time" && (
            <Message icon>
              <Icon name="warning sign" />
              <Message.Content>
                <Message.Header>No more training credit</Message.Header>
                Sorry, no more training credit available.
                <div style={{ paddingTop: "1em" }}>
                  <Button
                    primary
                    onClick={(event) => {
                      event.preventDefault(); // Prevent the default behavior
                      navigate("/subscribe"); // Handle the navigation manually
                    }}
                  >
                    Upgrade to train your model
                  </Button>
                </div>
              </Message.Content>
            </Message>
          )}
      </Container>

      <Container style={{ marginTop: "1em" }}>
        {renderTrainModelContent()}
      </Container>
      <Footer />
    </div>
  );
  // }
}

export default Train;

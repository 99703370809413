import React, { useState } from "react";
import axios from "axios";
import SiteHeader from "./SiteHeader";
import Footer from "./Footer";
import { Container, Form, Button, Input, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/forgot-password", { email });
      console.log(response.data);
      // Handle success, show success message, etc.
      setMessage("Please check your email inbox.");
    } catch (error) {
      console.error("Error during password reset:", error);
      // Handle error, show error message, etc.
      setMessage("Please check your email inbox.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <SiteHeader />
      <Container style={{ marginTop: "8em" }}>
        <Segment style={{ maxWidth: "400px", margin: "0 auto" }}>
          <h1>Reset password</h1>

          {message.length > 0 ? (
            <p>{message}</p>
          ) : (
            <React.Fragment>
              <p>
                Enter your email address to reset your password. You'll receieve
                an email with a reset password link.
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <Input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <Button type="submit">Reset Password</Button>
                </Form.Field>
              </Form>
            </React.Fragment>
          )}
        </Segment>
      </Container>
      <Footer />
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import {
  Segment,
  Container,
  Grid,
  Header,
  List,
  Divider,
} from "semantic-ui-react"; // Import necessary components from the Semantic UI React library
import { useNavigate } from "react-router-dom";
import LogoComponent from "./LogoComponent.js";

const Footer = ({ parent }) => {
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const scrollOffset = 80; // Adjust this value based on the fixed top menu's height
    const topPosition =
      section.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: topPosition - scrollOffset, behavior: "smooth" });
  };

  return (
    <Segment vertical style={{ margin: "5em 0em 0em", padding: "5em 0em" }}>
      <Container textAlign="center">
        <Grid divided stackable>
          <Grid.Column width={4}>
            <Header as="h4" content="Links" />
            <List link>
              {parent === "home" && (
                <React.Fragment>
                  <List.Item as="a" onClick={() => scrollToSection("examples")}>
                    Examples
                  </List.Item>
                  <List.Item
                    as="a"
                    onClick={() => scrollToSection("how-it-works")}
                  >
                    How it works
                  </List.Item>
                  <List.Item as="a" onClick={() => scrollToSection("pricing")}>
                    Pricing
                  </List.Item>
                </React.Fragment>
              )}
              <List.Item as="a" onClick={() => navigate("/blog")}>
                Blog
              </List.Item>
            </List>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header as="h4" content="Photoshoots" />
            <List link>
              <List.Item as="a" onClick={() => navigate("/tinder")}>
                Tinder Photoshoots
              </List.Item>
              <List.Item as="a" onClick={() => navigate("/headshots")}>
                Headshot Photoshoots
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header size="large" style={{textWrap: "balance"}}>
            Make incredible photos of yourself with AI
            </Header>
            <p>© Copyright 2023 All Rights Reserved by PhotoGen LTD</p>
          </Grid.Column>
        </Grid>

        <Divider section />
        <div style={{textAlign: "center"}}>
        <LogoComponent width={200}/>
        </div>
       
        <List horizontal divided link size="small">
          <List.Item as="a" href="mailto:support@photogen.io">
            Contact Us
          </List.Item>
          <List.Item as="a" onClick={() => navigate("/terms-and-conditions")}>
            Terms and Conditions
          </List.Item>
          <List.Item as="a" onClick={() => navigate("/privacy-policy")}>
            Privacy Policy
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";

const TimerComponent = () => {
  const [timer, setTimer] = useState(0);
  const [message, setMessage] = useState("Configuring AI");

  useEffect(() => {
    let startTime = Date.now();

    const interval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = Math.floor((currentTime - startTime) / 100); // Calculate elapsed time in tenths of a second
      setTimer(elapsed / 10); // Convert elapsed time to seconds with one decimal place
    }, 100); // Update timer every 0.1 second

    const messageInterval = setInterval(() => {
      setMessage((prevMessage) => {
        if (prevMessage === "Configuring AI") {
          return "Applying Options";
        } else if (prevMessage === "Applying Options") {
          return "Generating Image";
        } else if (prevMessage === "Generating Image") {
          return "Optimizing Results";
        }else {
          return "Configuring AI";
        }
      });
    }, 25000); // Change message every 25 seconds

    return () => {
      clearInterval(interval);
      clearInterval(messageInterval);
    };
  }, []);

  return (
    <div style={{textAlign: "center"}}>
      <div>{message}</div>
      <div>please wait around 90 seconds...</div>
      <div>{timer.toFixed(1)}s</div>
      
    </div>
  );
};

export default TimerComponent;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { marked } from "marked";
import DOMPurify from "dompurify";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { Container, Grid, Segment, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./Blog.css";
import { Helmet } from "react-helmet";

const BlogPost = () => {
  const { postName } = useParams();
  const [postContent, setPostContent] = useState("");
  const [tableOfContents, setTableOfContents] = useState([]);

  useEffect(() => {
    const customRenderer = new marked.Renderer();

    customRenderer.image = (href, title, text) => {
      return `<img src="${href}" alt="${text}" title="${text}" style="max-width: 100%"/>`;
    };

    // Fetch the content of the specific blog post based on the postName
    const fetchBlogPost = async () => {
      try {
        const response = await fetch(`/posts/${postName}.md`);
        const markdown = await response.text();
        const { metadata, content } = parseMarkdown(markdown);
        const html = marked(content, { renderer: customRenderer });
        setPostContent(html);

        // Override the page title with the post title
        if (metadata.title) {
          const title = document.title + " | " + metadata.title;
          document.title = title;
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchBlogPost();
  }, [postName]);

  // Parse the Markdown content and extract metadata and content
  const parseMarkdown = (markdown) => {
    const lines = markdown.split("\n");
    const metadata = {};
    let content = "";
    const headings = [];

    const slugify = (text) => {
      return text
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-|-$/g, "");
    };

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.startsWith("#")) {
        const headingLevel = line.match(/^(#+)\s/)[1].length;
        const headingText = line.replace(/^#+\s/, "");
        const slug = slugify(headingText);
        headings.push({ level: headingLevel, text: headingText, slug });
        content += `${line} <a id="${slug}"></a>\n`;
      } else if (line.startsWith("---")) {
        i++;
        while (i < lines.length && !lines[i].startsWith("---")) {
          const [key, value] = lines[i].split(":").map((s) => s.trim());
          metadata[key] = value;
          i++;
        }
      } else {
        content += line + "\n";
      }
    }

    setTableOfContents(headings);
    return { metadata, content };
  };

  const scrollHeadingIntoView = (slug) => {
    const headingElement = document.getElementById(slug);
    if (headingElement) {
      const scrollOffset = 85; // Adjust this value as needed
      const topPosition =
        headingElement.getBoundingClientRect().top + window.pageYOffset;
      console.log("topPosition", topPosition);
      window.scroll({
        top: topPosition - scrollOffset,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const canonicalUrl = "https://photogen.io/blog/" + postName;

  return (
    <div className="blog-post">
      <SiteHeader />
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Container>
        <Grid relaxed stackable style={{ marginTop: "5em" }}>
          <Grid.Row>
            <Grid.Column width={11}>
              <div style={{ paddingBottom: "1em" }}>
                <Link to={`/blog/`} className="underline-link">
                  <span className="underline-text">
                    <Icon name="arrow left" /> Back{" "}
                  </span>
                </Link>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(postContent),
                }}
              ></div>
            </Grid.Column>
            <Grid.Column width={5} style={{ minWidth: "100px" }}>
              <div className="table-of-contents sticky">
                <Segment padded style={{ fontSize: "1.0em" }}>
                  <h2>Table of Contents</h2>
                  <ul>
                    {tableOfContents.map((heading, index) => (
                      <li key={index}>
                        <a
                          href={`#${heading.slug}`}
                          onClick={(event) => {
                            event.preventDefault();
                            scrollHeadingIntoView(heading.slug);
                          }}
                        >
                          {heading.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Segment>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default BlogPost;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Header,
  Segment,
  Form,
  Button,
  Input,
  Message,
  Icon,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import SiteHeader from "./SiteHeader";
import Footer from "./Footer";
import PricingPage from "./Pricing";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

function Account() {
  const [token, setToken] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState([]);
  const [customerStatus, setCustomerStatus] = useState(null);
  const navigate = useNavigate();
  const [userModels, setUserModels] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");

  const handleToggleForm = () => {
    setShowLogin(!showLogin);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      console.log("token found");
    } else {
      console.log("token not found");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const login = urlParams.get("login");

    if (login) {
      setShowLogin(true);
    }
  }, []);

  useEffect(() => {
    const getUserModels = () => {
      axios
        .get("/api/user-models", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const models = response.data;
          //console.log(models);
          setUserModels(models);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const getUser = () => {
      axios
        .get("/api/user", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const user = response.data;
          console.log(user);
          setUser(user);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    const getCheckSubscription = () => {
      axios
        .get("/api/check-subscription", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const { customerStatus } = response.data;
          console.log(customerStatus);
          setCustomerStatus(customerStatus);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (token) {
      getUser();
      getCheckSubscription();
      getUserModels();
    }
  }, [token]);

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const handleLogin = (event) => {
    console.log("handle login called");
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    axios
      .post("/api/login", { email, password })
      .then((response) => {
        console.log(response.data); // Response from the server
        setToken(response.data.token);
        localStorage.setItem("token", response.data.token);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("Invalid credentials");
          setLoginErrorMessage(
            "Email or password incorrect. If you've forgotten you password, please email <a href='mailto:support@photogen.io'>support@photogen.io</a> to reset."
          );
          // Display an error message to the user indicating invalid credentials
        } else {
          console.error("Error:", error);
          // Handle other types of errors
        }
      });
  };

  const handleRegister = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    axios
      .post("/api/register", { email, password })
      .then((response) => {
        console.log(response.data); // Response from the server
        // Optionally, you can automatically log in the user after successful registration
        handleLogin(event);
        ReactGA.event({
          category: "User",
          action: "Registered",
          label: "Registered",
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          console.log("Email already registered");
          setRegisterErrorMessage("Email already registered");
          // Display an error message to the user indicating that the email is already registered
        } else {
          console.error("Error:", error);
        }
      });
  };

  const LoginForm = () => {
    return (
      <>
        <Helmet>
          <link rel="canonical" href="https://photogen.io/account/login" />
          <title>PhotoGen | Login</title>
          <meta name="description" content="Login" />
        </Helmet>
        <Form onSubmit={handleLogin}>
          <Form.Field>
            <div style={{ maxWidth: "400px" }}>
              <Input type="email" name="email" placeholder="Email" required />
            </div>
          </Form.Field>

          <Form.Field>
            <div style={{ maxWidth: "400px" }}>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                required
              />
            </div>
          </Form.Field>

          <Form.Field>
            <Button type="submit" color="blue">
              Sign in
            </Button>
          </Form.Field>
          <p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate("/forgot-password");
              }}
            >
              Forgot password?
            </a>
          </p>

          {loginErrorMessage.length > 0 && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>{/* {loginErrorMessage} */}</p>
              Email or password incorrect
            </Message>
          )}
        </Form>
      </>
    );
  };

  const RegisterForm = () => {
    return (
      <>
        <Helmet>
          <link rel="canonical" href="https://photogen.io/account/register" />
          <title>PhotoGen | Register</title>
          <meta name="description" content="Register" />
        </Helmet>
        <Form onSubmit={handleRegister}>
          <Form.Field>
            <div style={{ maxWidth: "400px" }}>
              <Input type="email" name="email" placeholder="Email" required />
            </div>
          </Form.Field>
          <Form.Field>
            <div style={{ maxWidth: "400px" }}>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long."
                required
              />
            </div>
          </Form.Field>
          <Form.Field>
            <Button type="submit" color="blue">
              Create account
            </Button>
          </Form.Field>
          {registerErrorMessage.length > 0 && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>{registerErrorMessage}</p>
            </Message>
          )}
        </Form>
      </>
    );
  };

  if (token && customerStatus === "subscribed") {
    console.log(userModels);
    navigate("/studio");
  } else if (token && customerStatus === "one-time") {
    console.log(userModels);
    navigate("/studio");
  }
  /*  else if (token && customerStatus === "demo") {
    
      console.log(userModels);
      navigate("/create");
    
  }  */

  if (!token) {
    return (
      <div>
        <SiteHeader handleLogout={handleLogout} token={token} />
        <div style={{ marginTop: "5em", textAlign: "left" }}>
          <Segment basic style={{ maxWidth: "800px", margin: "0 auto" }}>
            <Header as="h2" textAlign="left">
              {showLogin
                ? "Sign in"
                : "Enter your email address and set a password"}
            </Header>
            <Header as="h3" color="green" textAlign="left">
              {showLogin
                ? "Welcome back! Please sign in below."
                : "We'll securely store you photos and models in your account so you can access them at any time"}
            </Header>
            <p style={{ textAlign: "left", marginTop: "10px" }}>
              {showLogin ? "New user? " : "Already have an account? "}
              <a href="#" onClick={handleToggleForm}>
                {showLogin ? "Create account here" : "Sign in here"}
              </a>
            </p>
            {showLogin ? <LoginForm /> : <RegisterForm />}
          </Segment>
        </div>

        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <link rel="canonical" href="https://photogen.io/account/plans" />
          <title>PhotoGen | Plans</title>
          <meta name="description" content="Plans" />
        </Helmet>
        <SiteHeader handleLogout={handleLogout} token={token} />
        <Container style={{ marginTop: "8em" }}>
          <Header textAlign="left" as="h1">
            Chose an option below to continue
          </Header>
          {/* <div style={{textWrap: "balance"}}>Choose a plan below to continue.</div> */}
          <div style={{ marginTop: "1em" }}>
            <Icon name="check" color="green"></Icon> Cancel online at any time
          </div>
          <div>
            <Icon name="lock" color="yellow"></Icon> Secure checkout
          </div>
          {/*   <Message>
            <React.Fragment>
              <div
                style={{
                  marginBottom: "0.5em",
                }}
              >
                <Header size="medium" textAlign="center" color="red">
                  Limited time offer: Train a model and make 10 AI photos
                </Header>
              </div>

              <div>
                <PricingPage user={user} type="one-time" />
              </div>
            </React.Fragment>
          </Message> 
          <Header textAlign="center">Monthly plans</Header>*/}
          <div style={{ paddingTop: "1em" }}>
            <PricingPage user={user} />
          </div>
        </Container>
      </div>
    );
  }
}

export default Account;

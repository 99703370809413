import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { Container, Grid, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./Blog.css";
import slugify from "slugify"; // Import the slugify library
import { Helmet } from "react-helmet";

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch("/posts/index.json");
        const posts = await response.json();
        // Update the slug property for each post using slugify with punctuation removal
        const updatedPosts = posts.map((post) => ({
          ...post,
          slug: slugify(post.title, {
            lower: true,
            remove: /[.,#!$%&;:{}=\-_`~()]/g, // Remove punctuation characters
          }),
        }));
        // Sort the posts by date descending
        const sortedPosts = updatedPosts.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setBlogPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <div className="blog-list">
      <SiteHeader />
      <Helmet>
        <link rel="canonical" href="https://photogen.io/blog" />
      </Helmet>
      <Container style={{ paddingTop: "6em" }}>
        <h1 style={{ textAlign: "center" }}>
          Latest blog posts about AI photography
        </h1>
        <h2
          style={{
            textAlign: "center",
            color: "GrayText",
            fontSize: "1.2em",
            paddingBottom: "0em",
          }}
        >
          Learn more about AI photography and get inspired to create amazing AI
          photos.
        </h2>
        <Grid columns={3} stackable relaxed>
          <Grid.Row>
            {blogPosts.map((post) => (
              <Grid.Column key={post.slug}>
                <div className="blog-post" style={{ paddingTop: "3em" }}>
                  <div className="image-container">
                    <img src={post.image} alt={post.title} />
                  </div>
                  <p style={{ color: "GrayText" }}>
                    {new Date(post.date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                  <h3>{post.title}</h3>
                  <Link to={`/blog/${post.slug}`} className="underline-link">
                    <span className="underline-text">
                      Continue reading <Icon name="arrow right"/></span>
                  </Link>
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default BlogList;

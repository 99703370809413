import React, { useEffect, useState } from "react";
import axios from "axios";
import SiteHeader from "./SiteHeader";
import Footer from "./Footer";
import { Container, Segment, Form, Button, Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const ResetPassword = () => {
  const [tokenValid, setTokenValid] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");

  useEffect(() => {
    // Check token validity when the component mounts
    const token = new URLSearchParams(window.location.search).get("token");
    console.log(token);
    checkTokenValidity(token);
  }, []);

  const checkTokenValidity = async (token) => {
    try {
      const response = await axios.get(
        `/api/reset-password/validate-token?token=${token}`
      );
      setTokenValid(response.data.valid);
    } catch (error) {
      console.error("Error validating reset token:", error);
      setTokenValid(false);
    }
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setResetError("Passwords do not match.");
      return;
    }

    try {
      const token = new URLSearchParams(window.location.search).get("token");
      console.log(token);
      const response = await axios.post("/api/reset-password", {
        token,
        newPassword,
      });
      setResetSuccess(response.data.message);
    } catch (error) {
      console.error("Error resetting password:", error);
      setResetError("An error occurred while resetting your password.");
    }
  };

  if (!tokenValid) {
    return <div>Invalid token or token expired.</div>;
  }

  return (
    <div>
      <SiteHeader />
      <Container style={{ marginTop: "8em" }}>
        <Segment style={{ maxWidth: "400px", margin: "0 auto" }}>
          <h1>Reset password</h1>
          <p>Enter a new password below.</p>
          {resetError && <div>{resetError}</div>}
          {resetSuccess && <div>{resetSuccess}</div>}
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <Input
                type="password"
                value={newPassword}
                onChange={handlePasswordChange}
                placeholder="Password"
                required
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long."
              />
            </Form.Field>
            <Form.Field>
              <Input
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm password"
                required
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must contain at least one digit, one lowercase letter, one uppercase letter, and be at least 8 characters long."
              />
            </Form.Field>
            <Form.Field>
              <Button type="submit">Reset Password</Button>
            </Form.Field>
          </Form>
        </Segment>
      </Container>
      <Footer />
    </div>
  );
};

export default ResetPassword;

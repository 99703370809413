import React from "react";
import { Rating, Icon, Image, Grid, Container } from "semantic-ui-react";

const TestimonialCard = ({ image, description, name, date }) => (
  <div style={{ width: "200px", margin: "0 auto", textAlign: "center" }}>
    <div style={{ paddingBottom: "1em" }}>
      <Image centered circular src={image} size="tiny" alt="Testimonial" />
    </div>
    <div style={{ fontSize: "1.2em" }}>
      <Icon
        name="quote left"
        color="grey"
        size="small"
        style={{ marginRight: "5px" }}
      />
      {description}
      <Icon
        name="quote right"
        color="grey"
        size="small"
        style={{ marginLeft: "5px" }}
      />
    </div>
    <h4>{name}</h4>
    <div style={{ color: "grey" }} className="date">
      {date}
    </div>
    <div>
      <Rating icon="star" defaultRating={5} maxRating={5} disabled />
    </div>
  </div>
);

const TestimonialCards = ({ landingPageTestimonials }) => (
  <React.Fragment>
    {console.log("landingPageTestimonials", landingPageTestimonials)}

    <Container>
      <Grid stackable centered>
        <Grid.Row centered>
          {landingPageTestimonials && landingPageTestimonials.length > 0 ? (
            landingPageTestimonials.map((testimonial, index) => (
              <Grid.Column key={index} mobile={16} tablet={8} computer={4}>
                <div>
                  <TestimonialCard
                    image={testimonial.image}
                    description={testimonial.description}
                    name={testimonial.name}
                    date={testimonial.date}
                  />
                </div>
              </Grid.Column>
            ))
          ) : (
            <>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <div>
                  <TestimonialCard
                    image="/testimonialimages/testimonial1.jpg"
                    description="PhotGen exceeded my expectations, producing stunning and realistic images effortlessly"
                    name="Charlie"
                    date="Jun, 2023"
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <div>
                  {" "}
                  <TestimonialCard
                    image="/testimonialimages/testimonial2.jpg"
                    description="I was amazed by the quality and variety of photos generated by the PhotoGen AI, making it a go-to tool for all my visual needs"
                    name="Mei"
                    date="Sep, 2023"
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <div>
                  {" "}
                  <TestimonialCard
                    image="/testimonialimages/testimonial3.jpg"
                    description="PhotoGen has revolutionized my creative process, saving me time and effort while consistently delivering exceptional results"
                    name="Sam"
                    date="Dec, 2023"
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <div>
                  {" "}
                  <TestimonialCard
                    image="/testimonialimages/testimonial4.jpg"
                    description="With its advanced AI algorithms, PhotoGen has become an invaluable asset for my projects, providing me with professional-grade images in no time"
                    name="Irhaa"
                    date="Feb, 2024"
                  />
                </div>
              </Grid.Column>
            </>
          )}
        </Grid.Row>
      </Grid>
    </Container>
  </React.Fragment>
);

export default TestimonialCards;

import React, { useState, useEffect, Fragment, useCallback } from "react";
import axios from "axios";
import "./image-grid.css";
import "./App.css";
import "./Pricing";
import {
  Container,
  Header,
  Image,
  Form,
  Button,
  Message,
  Icon,
  Loader,
  Grid,
  Accordion,
  List,
  Segment,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import io from "socket.io-client";
import { saveAs } from "file-saver";
import TimerComponent from "./TimerComponent";
import ProcessingModels from "./ProcessingModels";
import ModalMessage from "./ModalMessage";

function Create() {
  const [token, setToken] = useState(null);
  const [userModels, setUserModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(
    "dc78c372-debd-4d92-81c7-4c977975c039"
  );
  const [promptText, setPromptText] = useState("");
  const [negativePromptText, setNegativePromptText] = useState("");
  const [generatedImage, setGeneratedImage] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [user, setUser] = useState([]);
  const [availableGenerations, setAvailableGenerations] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedShotType, setSelectedShotType] = useState("");
  const [selectedEnvironmentType, setSelectedEnvironmentType] = useState("");
  const [selectedAgeType, setSelectedAgeType] = useState("");
  const [selectedImageStyleType, setSelectedImageStyleType] =
    useState("Realistic");
  const [selectedHairColorType, setSelectedHairColorType] = useState("");
  const [selectedFrameType, setSelectedFrameType] = useState("768");
  const [selectedHairStyleType, setSelectedHairStyleType] = useState("");
  const [selectedApparelType, setSelectedApparelType] = useState("");
  const [selectedApparelColorType, setSelectedApparelColorType] = useState("");
  const [selectedFacialExpressionType, setSelectedFacialExpressionType] =
    useState("");
  const [selectedNSFWFilter, setSelectedNSFWFilter] = useState(true);
  const [selectedManual, setSelectedManual] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [imageData, setImageData] = useState("");
  const [viewerVisible, setViewerVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const [mode, setMode] = useState("");
  const initialGeneratedCount = parseInt(localStorage.getItem('generatedCount')) || 0;
  const [generatedCount, setGeneratedCount] = useState(initialGeneratedCount);

   // Update localStorage whenever generatedCount changes
   useEffect(() => {
    localStorage.setItem('generatedCount', generatedCount.toString());
  }, [generatedCount]);


  const getUserModels = useCallback(() => {
    axios
      .get("/api/user-models", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const models = response.data;
        // console.log(models);
        setUserModels(models);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [token]);

  // Get the current location and search (query) parameters using useLocation
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Use a flag to track whether the useEffect has run or not
  const [hasEffectRun, setHasEffectRun] = useState(false);

  // Retrieve the value of the 'model' query parameter and set it as the selectedModel state
  useEffect(() => {
    // Only run the effect if it hasn't already run
    if (!hasEffectRun) {
      const modelFromQuery = searchParams.get("model");
      if (modelFromQuery) {
        setSelectedModel(modelFromQuery);
      }

      // Set the flag to indicate that the effect has run
      setHasEffectRun(true);
    }
  }, [searchParams, hasEffectRun]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      const demoToken = process.env.REACT_APP_DEMO_USER;
      setToken(demoToken);
      setMode("demo");
    }
  }, []);

  useEffect(() => {
    if (generatedImages) {
      console.log("generated images useEffect", generatedImages);
    }
  }, [generatedImages]);

  useEffect(() => {
    if (token) {
      fetchAvailableGenerations();
      getUserModels();
      loadImages();
      getUser();

      //loadGeneratedImages();
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      console.log(user);
      const userID = user._id;
      console.log("userID ", userID);
      const socket = io({
        query: { userID },
      });

      socket.on("modelUpdated", (updatedModel) => {
        console.log("Model updated:", updatedModel);
        // Handle the updated model
        getUserModels();
      });

      return () => {
        socket.disconnect(); // Disconnect the socket connection when the component unmounts
      };
    }
  }, [user, getUserModels]);

  useEffect(() => {
    if (generatedImage) {
      // Check if generatedImage is an array before attempting to destructure it
      if (Array.isArray(generatedImage)) {
        setGeneratedImages((prevGeneratedImages) => [
          ...generatedImage,
          ...prevGeneratedImages,
        ]);
        // delete last item as the newly generated image has been written
        // to the file system so it changes the page offsets and returns the last image again
        // deleting it is a hack!
        //generatedImages.pop();
        //console.log("generatedImages", generatedImages);
      } else {
        // Handle the case where generatedImage is not an array (e.g., single image response)
        setGeneratedImages((prevGeneratedImages) => [
          generatedImage,
          ...prevGeneratedImages,
        ]);
      }

      setShowPlaceholder(false);
    }
  }, [generatedImage]);

  const fetchAvailableGenerations = async () => {
    try {
      const response = await axios.get(
        "/api/check-available-image-generations",
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const { available_image_generations, customerStatus } = response.data;
      setAvailableGenerations(available_image_generations);
      setSubscriptionStatus(customerStatus.customerStatus);
    } catch (error) {
      console.error("Error while fetching available image generations:", error);
    }
  };

  const getUser = () => {
    axios
      .get("/api/user", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const user = response.data;
        console.log(user);
        setUser(user);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const scrollOffset = 80; // Adjust this value based on the fixed top menu's height
    const topPosition =
      section.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: topPosition - scrollOffset, behavior: "smooth" });
  };

  const handleLogout = () => {
    setToken(null);

    setUserModels([]); // Clear the user models
    setSelectedModel(""); // Clear the selected model
    setPromptText(""); // Clear the prompt text
    setGeneratedImage(null); // Clear the generated image
    setGeneratedImages([]); // clear generated images
    localStorage.removeItem("token");
    navigate("/");
  };

  const loadGeneratedImages = (token, page) => {
    console.log("loadGeneratedImages called", page, token);
    return axios.get(`/api/generated-images?page=${page}`, {
      headers: {
        Authorization: token,
      },
      responseType: "json",
    });
  };

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleModelSelection = (model) => {
    setSelectedModel(model);
  };

  const handleNSFWFilter = (value) => {
    setSelectedNSFWFilter(value);
  };

  const handleManual = (value) => {
    setSelectedManual(value);
  };

  const handleShotTypeSelection = (value) => {
    setSelectedShotType(value);
  };

  const handleFrameTypeSelection = (value) => {
    setSelectedFrameType(value);
  };

  const handleEnvironmentTypeSelection = (value) => {
    setSelectedEnvironmentType(value);
  };

  const handleApparelSelection = (value) => {
    setSelectedApparelType(value);
  };

  const handleAgeSelection = (value) => {
    setSelectedAgeType(value);
  };

  const handleImageStyleSelection = (value) => {
    setSelectedImageStyleType(value);
  };

  const handleHairColorSelection = (value) => {
    setSelectedHairColorType(value);
  };

  const handleHairStyleSelection = (value) => {
    setSelectedHairStyleType(value);
  };

  const handleApparelColorSelection = (value) => {
    setSelectedApparelColorType(value);
  };

  const handleFacialExpressionSelection = (value) => {
    setSelectedFacialExpressionType(value);
  };

  const handlePromptChange = (event) => {
    setPromptText(event.target.value);
  };

  const handleNegativePromptChange = (event) => {
    setNegativePromptText(event.target.value);
  };

  /*   const handleModelSubmit = () => {
    setErrorMessage(null);
    setShowPlaceholder(true);
    scrollToSection("photos");

    if (selectedModel) {
      axios
        .post(
          "/api/generate-image",
          {
            modelId: selectedModel,
            prompt: promptText,
            shotType: selectedShotType,
            environmentType: selectedEnvironmentType,
            apparelType: selectedApparelType,
            apparelColorType: selectedApparelColorType,
            facialExpressionType: selectedFacialExpressionType,
            ageType: selectedAgeType,
            hairColorType: selectedHairColorType,
            hairStyleType: selectedHairStyleType,
            imageStyleType: selectedImageStyleType,
            frameType: selectedFrameType,
          },
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const generatedImage = response.data;
          console.log("generatedImage: ", generatedImage);
          // Update the generated image state
          setGeneratedImage(generatedImage);
        })
        .catch((error) => {
          console.error("Error:", error);
          setShowPlaceholder(false);
          console.log(error.response.data)

                     const errorResponse = error.response.data.error;
          //if (errorResponse) {
          //  const errorMessage = errorResponse.includes("NSFW")
          //    ? "NSFW content detected. Please try again, or try another prompt."
          //    : errorResponse; 
          //const errorMessage = "Sorry, there was an error. Please try again.";
          const errorMessage = errorResponse
          setErrorMessage(errorMessage);
          //}
        });
    }
  }; */

  const handleModelSubmit = () => {
    setErrorMessage(null);
    setShowPlaceholder(true);
    scrollToSection("photos");

    if (selectedModel) {
      axios
        .post(
          "/api/generate-image",
          {
            modelId: selectedModel,
            prompt: promptText,
            negativePrompt: negativePromptText,
            shotType: selectedShotType,
            environmentType: selectedEnvironmentType,
            apparelType: selectedApparelType,
            apparelColorType: selectedApparelColorType,
            facialExpressionType: selectedFacialExpressionType,
            ageType: selectedAgeType,
            hairColorType: selectedHairColorType,
            hairStyleType: selectedHairStyleType,
            imageStyleType: selectedImageStyleType,
            frameType: selectedFrameType,
            NSFWFilter: selectedNSFWFilter,
            manual: selectedManual,
          },
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const { taskId } = response.data;
          console.log("Task ID:", taskId);
          // Start polling for the status using the taskId
          pollImageGenerationStatus(taskId);
        })
        .catch((error) => {
          console.error("Error:", error);
          setShowPlaceholder(false);
          const errorResponse = error.response.data.error;
          const errorMessage = errorResponse;
          setErrorMessage(errorMessage);
        });
    }
  };

  const pollImageGenerationStatus = (taskId) => {
    const pollInterval = 5000; // Polling interval in milliseconds (2 seconds in this example)
    const maxAttempts = 60; // Maximum number of attempts (2 seconds interval * 60 attempts = 2 minutes)
    const maxConsecutive502Errors = 5; // Maximum consecutive 502 errors before showing error message

    let attempts = 0;
    let consecutive502Errors = 0;

    const pollStatus = () => {
      axios
        .get(`/api/check-image-generation/${taskId}`)
        .then((response) => {
          const task = response.data;

          if (task.status === "completed") {
            // Image generation is completed, update the generated image state
            setGeneratedImage(task.result);
            setShowPlaceholder(false);
            setGeneratedCount((prevCount) => prevCount + 1);
          } else if (task.status === "failed") {
            // Image generation failed, display an error message
            setShowPlaceholder(false);
            setErrorMessage("Image generation failed. Please try again.");
          } else {
            // Image generation is still in progress, continue polling
            attempts++;
            if (attempts < maxAttempts) {
              setTimeout(pollStatus, pollInterval);
            } else {
              // Max attempts reached, display an error message
              setShowPlaceholder(false);
              setErrorMessage("Image generation timed out. Please try again.");
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);

          consecutive502Errors++;

          if (consecutive502Errors >= maxConsecutive502Errors) {
            // Reached max consecutive 502 errors, display an error message
            setShowPlaceholder(false);
            setErrorMessage(
              "Error while checking image generation status. Please try again."
            );
          } else {
            // Retry the polling after a short delay (500 milliseconds)
            setTimeout(pollStatus, 500);
          }
        });
    };

    // Start polling for the status initially
    pollStatus();
  };

  const loadImages = () => {
    console.log("loadImages called", page);
    if (!hasMore || isLoading) return;

    setIsLoading(true);
    loadGeneratedImages(token, page)
      .then((response) => {
        const { generatedImages: newGeneratedImages } = response.data;
        // delete last items as the newly generateds image have been written
        // to the file system so they change page offsets and returns the last images again

        if (newGeneratedImages.length > 0) {
          const remainder = generatedImages % 12;

          for (let i = 0; i < remainder; i++) {
            generatedImages.pop();
          }
        }

        setGeneratedImages((prevImages) => [
          ...prevImages,
          ...newGeneratedImages,
        ]);
        //setShowPlaceholder(false);
        //console.log("generatedImages: ", generatedImages);
        setPage((prevPage) => prevPage + 1);
        setHasMore(newGeneratedImages.length > 0);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  };

  const [loaderRef, inView] = useInView({
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView) {
      //setPage((prevPage) => prevPage + 1);

      loadImages();
    }
  }, [inView]);

  /*   const handleDownload3 = (filename) => {
    setIsDownloading(true);
    axios({
      method: "GET",
      url: `/api/download-image?filename=${filename}`,
      responseType: "text",
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        //console.log("response.data: ", response.data)
        //const blob = new Blob([response.data], { type: "image/png" });

        const base64String = response.data;
        const binaryString = window.atob(base64String);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: "image/png" });

        saveAs(blob, filename);
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsDownloading(false);
      });
  }; */

  const handleDownload3 = (filename) => {
    setIsDownloading(true);
    axios({
      method: "GET",
      url: `/api/download-image?filename=${filename}`,
      responseType: "arraybuffer", // Set responseType to 'arraybuffer'
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        const byteArray = new Uint8Array(response.data);
        const blob = new Blob([byteArray], { type: "image/png" });

        saveAs(blob, filename);
        setIsDownloading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsDownloading(false);
      });
  };

  const handleView = (filename) => {
    // Make API call to retrieve the image
    axios
      .get(`/api/view-image?filename=${filename}`, {
        responseType: "blob",
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageData(reader.result);
        };
        reader.readAsDataURL(response.data);
        setViewerVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const closeViewer = () => {
    setViewerVisible(false);
    setImageData(null);
  };

  const imageStyleTypes = [
    "Anime",
    "Manga",
    "Watercolor",
    "Pointillist",
    "Pop Art",
    "Impressionist",
    "Cubist",
    "Surrealist",
    "Expressionist",
    "Retro",
  ];

  const shotTypes = [
    "Extreme close-up",
    "Close-up",
    "Medium close-up",
    "Medium shot",
    "Medium full shot",
    "Full shot",
    "Long shot",
    "Extreme long shot",
  ];

  const frameTypes = [
    ["Portrait", "768"],
    ["Square", "512"],
  ];

  const hairStyleTypes = [
    "Short Bob",
    "Long Wavy",
    "Pixie Cut",
    "Messy Bun",
    "Sleek Ponytail",
    "Curly Afro",
    "Braided Updo",
    "Top Knot",
    "Layered Shag",
    "Mohawk",
    "Side Swept Bangs",
    "Beach Waves",
    "Half-Up Half-Down",
    "Bald",
    "Cornrows",
    "Fishtail Braid",
    "Dreadlocks",
    "Spiky Hair",
    "French Twist",
    "High Fade",
    "Bouffant",
    "Chignon",
    "Pompadour",
    "Crew Cut",
    "Mullet",
    "Quiff",
    "Undercut",
    "Bantu Knots",
    "Bowl Cut",
    "Pigtails",
  ];

  const hairColorTypes = [
    "Black",
    "Brown",
    "Blonde",
    "Red",
    "Brunette",
    "Chestnut",
    "Auburn",
    "Gray",
    "Silver",
    "Platinum",
    "White",
    "Dirty Blonde",
    "Honey Blonde",
    "Caramel",
    "Strawberry Blonde",
    "Burgundy",
    "Mahogany",
    "Jet Black",
    "Dark Brown",
    "Light Brown",
    "Golden Brown",
    "Sandy Blonde",
    "Ash Blonde",
    "Balayage",
    "Ombre",
    "Highlights",
    "Pastel",
    "Rainbow",
    "Multicolored",
    "Natural",
  ];

  const ageTypes = [
    "18-25",
    "25-30",
    "30-35",
    "35-40",
    "40-45",
    "45-50",
    "50-60",
    "60-70",
  ];

  const apparelTypes = [
    "T-shirt",
    "Jeans",
    "Dress",
    "Shirt",
    "Skirt",
    "Sweater",
    "Shorts",
    "Jacket",
    "Hoodie",
    "Blouse",
    "Pants",
    "Coat",
    "Suit",
    "Top",
    "Blazer",
    "Leggings",
    "Trousers",
    "Cardigan",
    "Tank Top",
    "Sweatshirt",
  ];

  const apparelColorTypes = [
    "Black",
    "White",
    "Gray",
    "Red",
    "Blue",
    "Green",
    "Yellow",
    "Purple",
    "Pink",
    "Orange",
    "Brown",
    "Beige",
    "Navy",
    "Teal",
    "Burgundy",
    "Olive",
    "Cream",
    "Silver",
    "Gold",
  ];

  const facialExpressionTypes = [
    "Smiling",
    "Laughing",
    "Crying",
    "Angry",
    "Surprised",
    "Confused",
    "Excited",
    "Disgusted",
    "Winking",
    "Pouting",
    "Blushing",
    "Squinting",
    "Skeptical",
    "Amused",
    "Shocked",
    "Doubtful",
    "Sighing",
    "Flirting",
    "Grinning",
  ];

  const environmentTypes = [
    ["Beach", "beach with sunny clear blue sky, turquoise sea and white sand"],
    ["Gym", "in action, at the fitness center"],
    ["Office", "at the office"],
    ["Restaurant", "at the restaurant"],
    ["Outside", "outside"],
    ["City", "in the city"],
    ["Bar", "sitting at a table in an elegant cocktail bar"],
    ["Swimming pool", "hotel outdoor pool, swimwear"],
    [
      "Photography studio - plain background",
      "photobooth, headshot, plain textured offwhite background",
    ],
  ];

  const ImageViewer = ({ imageData, closeViewer }) => {
    const handleImageClick = (event) => {
      event.stopPropagation();
      event.target.classList.toggle("zoomed");
    };

    return (
      <div className="image-viewer-mask" onClick={closeViewer}>
        <div className="image-viewer">
          <div className="image-viewer-content">
            <img
              src={imageData}
              alt="Viewer"
              onClick={handleImageClick}
              className="zoomable"
            />
          </div>
        </div>
      </div>
    );
  };

  const panelContent = (
    <React.Fragment>
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column>
            {/* Apparel */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="apparelType">Apparel (optional)</label>
              <select
                id="apparelType"
                value={selectedApparelType}
                onChange={(event) => handleApparelSelection(event.target.value)}
              >
                <option value="">Choose apparel</option>
                {apparelTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            {/* Apparel Color*/}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="apparelColorType">Apparel Color (optional)</label>
              <select
                id="apparelColorType"
                value={selectedApparelColorType}
                onChange={(event) =>
                  handleApparelColorSelection(event.target.value)
                }
              >
                <option value="">Choose apparel color</option>
                {apparelColorTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {/* Facial Expression */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="facialExpressionType">Emotion (optional)</label>
              <select
                id="facialExpressionType"
                value={selectedFacialExpressionType}
                onChange={(event) =>
                  handleFacialExpressionSelection(event.target.value)
                }
              >
                <option value="">Choose emotion</option>
                {facialExpressionTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            {/* Age */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="ageType">Age range (optional)</label>
              <select
                id="ageType"
                value={selectedAgeType}
                onChange={(event) => handleAgeSelection(event.target.value)}
              >
                <option value="">Choose age range</option>
                {ageTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {/* hair Color */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="hairColorType">Hair color (optional)</label>
              <select
                id="hairColorType"
                value={selectedHairColorType}
                onChange={(event) =>
                  handleHairColorSelection(event.target.value)
                }
              >
                <option value="">Choose hair color</option>
                {hairColorTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            {/* hair style */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="hairStyleType">Hair style (optional)</label>
              <select
                id="hairStyleType"
                value={selectedHairStyleType}
                onChange={(event) =>
                  handleHairStyleSelection(event.target.value)
                }
              >
                <option value="">Choose hair style</option>
                {hairStyleTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {/* Image Style */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="imageStyleType">Image Style (optional)</label>
              <p>
                Avoid choosing too many other options when using styles other
                than "Realistic"
              </p>
              <select
                id="imageStyleType"
                value={selectedImageStyleType}
                onChange={(event) =>
                  handleImageStyleSelection(event.target.value)
                }
              >
                <option key="Realistic" value="Realistic">
                  Realistic
                </option>
                {imageStyleTypes.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="alt-background-color2">
          <Grid.Column>
            <Header>Expert settings</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="alt-background-color2">
          <Grid.Column>
            {/* Prompt */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="prompt">
                Prompt - things to include (optional)
              </label>
              <textarea
                rows="3"
                type="text"
                id="prompt"
                value={promptText}
                onChange={handlePromptChange}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            {/* Negative Prompt */}
            <Form.Field style={{ maxWidth: "400px" }}>
              <label htmlFor="negativePrompt">
                Negative prompt - things to exclude (optional)
              </label>
              <textarea
                rows="3"
                type="text"
                id="negativePrompt"
                value={negativePromptText}
                onChange={handleNegativePromptChange}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="alt-background-color2">
          <Grid.Column>
            {/* NSFW */}

            {mode === "demo" || subscriptionStatus === "demo" ? (
              <Form.Field style={{ maxWidth: "400px" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey",
                  }}
                >
                  <input
                    disabled="true"
                    type="checkbox"
                    checked={selectedNSFWFilter}
                    onChange={(e) => handleNSFWFilter(e.target.checked)}
                    style={{ marginRight: "8px" }}
                  />
                  Enable explicit images filter?
                </label>
                <div style={{ color: "grey", paddingLeft: "20px" }}>
                  Not available in demo mode
                </div>
              </Form.Field>
            ) : (
              <Form.Field style={{ maxWidth: "400px" }}>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={selectedNSFWFilter}
                    onChange={(e) => handleNSFWFilter(e.target.checked)}
                    style={{ marginRight: "8px" }}
                  />
                  Enable explicit images filter?
                </label>
              </Form.Field>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="alt-background-color2">
          <Grid.Column>
            {/* manual mode */}

            {mode === "demo" || subscriptionStatus === "demo" ? (
              <Form.Field style={{ maxWidth: "400px" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey",
                  }}
                >
                  <input
                    disabled="true"
                    type="checkbox"
                    checked={selectedManual}
                    onChange={(e) => handleManual(e.target.checked)}
                    style={{ marginRight: "8px" }}
                  />
                  Manual prompting (ignores dropdowns)?
                </label>
                <div style={{ color: "grey", paddingLeft: "20px" }}>
                  Not available in demo mode
                </div>
              </Form.Field>
            ) : (
              <Form.Field style={{ maxWidth: "400px" }}>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={selectedManual}
                    onChange={(e) => handleManual(e.target.checked)}
                    style={{ marginRight: "8px" }}
                  />
                  Manual prompting (ignores dropdowns)?
                </label>
              </Form.Field>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );

  const panels = [
    {
      key: "form-panel",
      title: {
        content: <span style={{ fontSize: "1.2em" }}>More options</span>,
        icon: <Icon name="dropdown" style={{ fontSize: "1.2em" }} />,
      },
      content: {
        content: panelContent,
      },
    },
  ];

  const renderCreatePhotosTab = () => {
    if (userModels && !userModels.length) {
      return <p>Please upload some photos and train a model first</p>; // Return null if there are no userModels
    }
    return (
      <div>
        {viewerVisible && (
          <ImageViewer imageData={imageData} closeViewer={closeViewer} />
        )}

        <Form>
          {userModels.length > 0 ? (
            <Fragment>
              <Grid columns={2} stackable>
                <Grid.Row>
                  <Grid.Column>
                    {/* Model */}
                    <Form.Field style={{ maxWidth: "400px" }}>
                      <label htmlFor="model">Model</label>
                      <select
                        id="model"
                        // default the selected model
                        value={
                          !selectedModel &&
                          userModels.includes(
                            "dc78c372-debd-4d92-81c7-4c977975c039"
                          )
                            ? "dc78c372-debd-4d92-81c7-4c977975c039"
                            : selectedModel
                        }
                        onChange={(event) =>
                          handleModelSelection(event.target.value)
                        }
                      >
                        <option value="">Choose model</option>
                        {userModels.map((model) => (
                          <option
                            key={model.id}
                            value={model.id}
                            disabled={model.status !== "COMPLETED"}
                          >
                            {model.model_name +
                              (model.status === "COMPLETED"
                                ? ""
                                : model.status === "FAILED"
                                ? " - please contact support@photogen.io"
                                : " - in progress")}
                          </option>
                        ))}
                      </select>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    {/* Frame */}
                    <Form.Field style={{ maxWidth: "400px" }}>
                      <label htmlFor="frameType">Format</label>
                      <select
                        id="frameType"
                        value={selectedFrameType}
                        onChange={(event) =>
                          handleFrameTypeSelection(event.target.value)
                        }
                      >
                        <option value="">Choose format</option>
                        {frameTypes.map(([type, description]) => (
                          <option key={type} value={description}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {/* Environment */}
                    <Form.Field style={{ maxWidth: "400px" }}>
                      <label htmlFor="environmentType">
                        Location (optional)
                      </label>
                      <select
                        id="environmentType"
                        value={selectedEnvironmentType}
                        onChange={(event) =>
                          handleEnvironmentTypeSelection(event.target.value)
                        }
                      >
                        <option value="">Choose location</option>
                        {environmentTypes.map(([type, description]) => (
                          <option key={type} value={description}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    {/* Shot type */}
                    <Form.Field style={{ maxWidth: "400px" }}>
                      <label htmlFor="shotType">Shot (optional)</label>
                      <select
                        id="shotType"
                        value={selectedShotType}
                        onChange={(event) =>
                          handleShotTypeSelection(event.target.value)
                        }
                      >
                        <option value="">Choose shot</option>
                        {shotTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Accordion
                style={{ paddingTop: "1em", paddingBottom: "1em" }}
                fluid
                exclusive={true}
                panels={panels}
                activeIndex={activeIndex}
                onTitleClick={handleAccordionClick}
              />

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    {/*  {JSON.stringify(user).replace(/,/g, ' ')} */}
                    {/*   <Message compact info>
                      <div style={{ fontSize: "1.2em" }}>
                        {user.available_image_generations} photos left
                      </div>
                    </Message> */}

                    <Form.Field>
                      <Button
                        style={{ marginTop: "1em" }}
                        onClick={handleModelSubmit}
                        disabled={
                          availableGenerations === 0 ||
                          !selectedModel ||
                          (subscriptionStatus !== "subscribed" &&
                            subscriptionStatus !== "one-time" &&
                            subscriptionStatus !== "demo")
                        }
                        size="large"
                        color="blue"
                      >
                        Create AI photo
                      </Button>

                      {token &&
                        subscriptionStatus !== "subscribed" &&
                        subscriptionStatus !== "one-time" &&
                        subscriptionStatus !== "demo" && (
                          <Message negative icon>
                            <Icon name="warning sign" />
                            <Message.Content>
                              <Message.Header>
                                Subscription expired
                              </Message.Header>
                              Sorry, your subscription has expired.
                              <div style={{ paddingTop: "1em" }}>
                                <Button
                                  primary
                                  onClick={(event) => {
                                    event.preventDefault(); // Prevent the default behavior
                                    navigate("/account"); // Handle the navigation manually
                                  }}
                                >
                                  Subscribe
                                </Button>
                              </div>
                            </Message.Content>
                          </Message>
                        )}
                      {token &&
                        availableGenerations === 0 &&
                        subscriptionStatus === "one-time" && (
                          <Message icon>
                            <Icon name="warning sign" />
                            <Message.Content>
                              <Message.Header>
                                No more generation credit
                              </Message.Header>
                              Sorry, you can't generate more images.
                              <div style={{ paddingTop: "1em" }}>
                                <Button
                                  primary
                                  onClick={(event) => {
                                    event.preventDefault(); // Prevent the default behavior
                                    navigate("/subscribe"); // Handle the navigation manually
                                  }}
                                >
                                  Upgrade to create more photos
                                </Button>
                              </div>
                            </Message.Content>
                          </Message>
                        )}

                      {availableGenerations === 0 &&
                        subscriptionStatus === "subscribed" && (
                          <Message icon color="orange">
                            <Icon name="warning sign" />
                            <Message.Content>
                              <Message.Header>
                                No more generation credit
                              </Message.Header>
                              Sorry, you can't generate more images this month.
                              Contact us if you want to add more credits.
                            </Message.Content>
                          </Message>
                        )}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Fragment>
          ) : (
            <p>No models found</p>
          )}
        </Form>

        <Header as="h2" id="photos">
          Your photos
        </Header>

        {(mode === "demo" || subscriptionStatus === "demo") &&
          generatedCount > 0 && (
            <Message info>
              <div style={{ paddingTop: "0.5em", fontWeight: "bold" }}>
                Like what you see? Want to make your own professional photos at
                a fraction of the cost of a photographer?
                <div style={{ paddingTop: "0.5em" }}>
                  <Button
                    onClick={(event) => {
                      event.preventDefault(); // Prevent the default behavior
                      navigate("/train"); // Handle the navigation manually
                    }}
                    color="orange"
                  >
                    Make your photos now
                  </Button>
                </div>
              </div>
            </Message>
          )}

        {errorMessage && (
          <Message icon warning>
            <Icon name="warning sign" />
            <Message.Content>
              <Message.Header>Sorry, there was an error</Message.Header>
              <p>{errorMessage}</p>
            </Message.Content>
          </Message>
        )}
        {generatedImage && generatedImage.inpainted === false && (
          <Message icon warning>
            <Icon name="warning sign" />
            <Message.Content>
              <Message.Header>Possible image generation issue</Message.Header>
              <p>
                We detected your image may not have generated correctly. This
                can happen occasionally. Please try again or try different
                settings.
              </p>
            </Message.Content>
          </Message>
        )}
        {/*         {generatingTimeMessageVisible && (
          <Message icon onDismiss={handleDismiss}>
            <Icon name="info circle" size="tiny" />
            Generating photos can take up to 1 minute
          </Message>
        )} */}

        <div className="image-grid">
          {/* Placeholder image */}
          {showPlaceholder && (
            <div
              className="ui placeholder"
              style={{
                borderRadius: "2%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "300px",
                minHeight: "400px",
              }}
            >
              <TimerComponent />
            </div>
          )}

          {generatedImages.map((image, index) => (
            <div
              key={image.filename}
              style={{
                position: "relative",
                display: "inline-block",
                overflow: "hidden",
                borderRadius: "2%",
                maxWidth: "300px",
              }}
              onMouseEnter={() => setActiveImage(index)}
              onMouseLeave={() => setActiveImage(null)}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.3)",
                  opacity: activeImage === index ? 1 : 0,
                  transition: "opacity 0.0s",
                  zIndex: 1,
                  borderRadius: "2%",
                }}
              />
              <Image
                rounded
                src={`data:image/jpeg;base64,${image.data}`}
                alt={`Generated ${image.filename}`}
                className="image"
                style={{
                  width: "100%",
                  height: "auto",
                  zIndex: 0,
                  maxWidth: "300px",
                }}
              />
              {activeImage === index && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 2,
                  }}
                >
                  {!isDownloading && (
                    <Button
                      basic
                      circular
                      icon
                      inverted
                      size="big"
                      onClick={() => handleDownload3(image.filename)}
                    >
                      <Icon name="download" />
                    </Button>
                  )}
                  {isDownloading && (
                    <Icon
                      basic
                      inverted
                      name="circle notch"
                      loading
                      size="big"
                    ></Icon>
                  )}
                  <Button
                    basic
                    circular
                    icon
                    inverted
                    size="big"
                    onClick={() => handleView(image.filename)}
                  >
                    <Icon name="expand" />
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>

        {isLoading && (
          <div align="center" style={{ paddingTop: "1em" }}>
            <Loader active inline="centered"></Loader>
          </div>
        )}
        {hasMore && !isLoading && <div ref={loaderRef}></div>}
      </div>
    );
  };

  if (!token) {
    return <div>not logged in</div>;
  } else {
    return (
      <div>
        <SiteHeader
          handleLogout={handleLogout}
          token={token}
          parent={"studio"}
          models={"true"}
          user={user}
          mode={mode}
        />
        <Container>
          <Container style={{ marginTop: "6em" }}>
            {availableGenerations === 0 && subscriptionStatus === "demo" && (
              <Message icon info>
                <Icon name="clock outline" />
                <Message.Content>
                  <Message.Header>
                    Sorry, your demo access has expired
                  </Message.Header>
                  <div style={{ marginTop: "1em" }}>
                    No worries, it's simple to continue!
                  </div>
                  <div>
                    Choose from one of our subscriptions to train a model of
                    yourself and create more photos.
                  </div>
                  <div style={{ paddingTop: "1em" }}>
                    <Button
                      primary
                      onClick={(event) => {
                        event.preventDefault(); // Prevent the default behavior
                        navigate("/subscribe"); // Handle the navigation manually
                      }}
                    >
                      Upgrade to create more photos
                    </Button>
                  </div>
                </Message.Content>
              </Message>
            )}
            {availableGenerations === 0 &&
              subscriptionStatus === "one-time" && (
                <Message icon>
                  <Icon name="warning sign" />
                  <Message.Content>
                    <Message.Header>No more generation credit</Message.Header>
                    Sorry, you can't generate more images.
                    <div style={{ paddingTop: "1em" }}>
                      <Button
                        primary
                        onClick={(event) => {
                          event.preventDefault(); // Prevent the default behavior
                          navigate("/subscribe"); // Handle the navigation manually
                        }}
                      >
                        Upgrade to create more photos
                      </Button>
                    </div>
                  </Message.Content>
                </Message>
              )}
            <Header as="h1">Make photos</Header>
            {/* help message for demo users */}
            {availableGenerations > 0 && subscriptionStatus === "demo" && (
              <Message info>
                <Message.Content>
                  {mode === "demo" || subscriptionStatus === "demo" ? (
                    <div>
                      {/*  <Message.Header>This is a free PhotoGen demo</Message.Header> */}
                      <div
                        style={
                          {
                            /* paddingTop: "1em"  */
                          }
                        }
                      >
                        Use our demo models Aisha and Sophia below to see how
                        PhotoGen works
                      </div>

                      {generatedCount > 0 && (
                        <div style={{ paddingTop: "1em", fontWeight: "bold" }}>
                          Stunnning, professional photos at a fraction of the
                          cost of a photographer?
                          {/*    <a
                          href="#"
                          style={{
                            marginLeft: "0.3em",
                            textDecoration: "underline",
                          }}
                          primary
                          onClick={(event) => {
                            event.preventDefault(); // Prevent the default behavior
                            navigate("/account"); // Handle the navigation manually
                          }}
                        >
                          Make your own photos now
                        </a> */}
                          <div style={{ paddingTop: "0.5em" }}>
                            <Button
                              onClick={(event) => {
                                event.preventDefault(); // Prevent the default behavior
                                navigate("/train"); // Handle the navigation manually
                              }}
                              color="orange"
                            >
                              Make your photos now
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Message.Header>Welcome{/*user.email*/}!</Message.Header>
                  )}

                  {/* <Header size="small" style={{ paddingTop: "1em" }}>
                    {" "}
                    Make high quality, realistic photos with AI
                  </Header>
                  <div style={{ marginTop: "1em" }}>
                    <p>
                      Check out PhotoGen's AI photo generation below with our
                      demo models, Sophia & Aisha.
                    </p>
                    <List relaxed>
                       <List.Item>
                        <List.Icon name="check" color="green" />
                        <List.Content>
                          Choose some options to see how easy it is to customise
                          photos.
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="check" color="green" />
                        <List.Content>
                          {" "}
                          Once you've chosen some options, click the "Create AI
                          photo" button.
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Icon name="check" color="green" />
                        <List.Content>
                          {" "}
                          Sit back whilst the AI generates a photo based on the
                          options you chose.
                        </List.Content>
                      </List.Item> */}
                  {/*   {mode === "demo" && (
                        <List.Item>
                          <List.Icon name="info" color="green" />
                          <List.Content>
                            You'll need to log in or create an account to train
                            your own model.
                          </List.Content>
                          <div style={{ paddingTop: "1em" }}>
                            <Button
                              primary
                              onClick={(event) => {
                                event.preventDefault(); // Prevent the default behavior
                                navigate("/account"); // Handle the navigation manually
                              }}
                            >
                              Log in or create an account
                            </Button>
                          </div>
                        </List.Item>
                      )} 
                    </List>
                  </div>*/}
                </Message.Content>
              </Message>
            )}

            <ProcessingModels />
          </Container>
        </Container>
        <Container id="tabs" style={{ marginTop: "1em" }}>
          {renderCreatePhotosTab()}
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Create;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./image-grid.css";
import "./App.css";
import "./Pricing";
import {
  Container,
  Divider,
  Grid,
  Header,
  Segment,
  Button,
  Icon,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { useNavigate } from "react-router-dom";
import Train from "./Train";

function Studio() {
  const [token, setToken] = useState(null);
  const [userModels, setUserModels] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const getUserModels = () => {
      axios
        .get("/api/user-models", {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const models = response.data;
          console.log(models);
          setUserModels(models);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (token) {
      getUserModels();
      getUser();

      //loadGeneratedImages();
    }
  }, [token]);


  const getUser = () => {
    axios
      .get("/api/user", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const user = response.data;
        console.log(user);
        setUser(user);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    navigate("/");
  };

  if (userModels && userModels.length) {
    return (
      <div>
        <SiteHeader
          handleLogout={handleLogout}
          token={token}
          parent={"studio"}
          models={userModels}
          user={user}
        />
        <Container style={{ marginTop: "6em" }}>
          <Header>What would you like to do today?</Header>

          <Segment placeholder>
            <Grid columns={2} textAlign="center">
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Header icon>
                    <Icon name="cogs" />
                    Train a model
                  </Header>
                  <p>
                    Choose this option to upload photos and create a new AI
                    model.
                  </p>


                  {user && user.stripe_subscription_status === "demo" ? (
              <Button
              color="orange"
                onClick={(event) => {
                  event.preventDefault(); // Prevent the default behavior
                  navigate("/subscribe"); // Handle the navigation manually
                }}
              >
                Subscripe to train a model
              </Button>
            )
            :
            (
              <Button
              onClick={(event) => {
                event.preventDefault(); // Prevent the default behavior
                navigate("/train"); // Handle the navigation manually
              }}
              color="blue"
            >
              Train a new model
            </Button>

            )
          }

             
                </Grid.Column>

                <Grid.Column>
                  <Header icon>
                    <Icon name="camera" />
                    Make AI photos
                  </Header>
                  <p>Choose this option to make AI photos using your model.</p>
                  <Button
                    onClick={(event) => {
                      event.preventDefault(); // Prevent the default behavior
                      navigate("/create"); // Handle the navigation manually
                    }}
                    color="blue"
                  >
                    Make AI photos
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider vertical>Or</Divider>
          </Segment>
        </Container>
        <Footer />
      </div>
    );
  } else if (!userModels) {
    <div>loading...</div>;
  } else {
    return (
      <div>
        <Train />
      </div>
    );
  }
}

export default Studio;

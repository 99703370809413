import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown, Menu, Icon, Button } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
//import logoImage from "./logo1/pixiologo.png";
import LogoComponent from "./LogoComponent.js";
import { useNavigate } from "react-router-dom";

function SiteHeader({ handleLogout, token, parent, models, user, mode }) {
  const navigate = useNavigate();

  console.log("mode", mode);

  const navigateTo = (route) => {
    navigate(route);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const scrollOffset = 40; // Adjust this value based on the fixed top menu's height
    const topPosition =
      section.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: topPosition - scrollOffset, behavior: "smooth" });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const mobileBreakpoint = 960;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenBillingPortal = async () => {
    try {
      console.log("token", token);
      const response = await axios.get("/api/create-customer-portal-session", {
        headers: {
          Authorization: `${token}`,
        },
      });
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error opening billing portal:", error);
    }
  };

  const shouldShowMenuItems = windowWidth >= mobileBreakpoint;

  return (
    <div>
      <Menu
        borderless
        fixed="top"
        style={{ background: "#FFFFFF", fontSize: "1em" }}
      >
        <Menu.Item onClick={() => navigate("/")} style={{ width: "200px" }}>
          <LogoComponent width={200} />
        </Menu.Item>
        {parent === "home" && shouldShowMenuItems && (
          <React.Fragment>
            <Menu.Item onClick={() => scrollToSection("pricing")}>
              Pricing
            </Menu.Item>
            <Menu.Item onClick={() => scrollToSection("video")}>
              How it works
            </Menu.Item>
            <Menu.Item onClick={() => scrollToSection("examples")}>
              Examples
            </Menu.Item>

            <Menu.Item onClick={() => scrollToSection("testimonials")}>
              Testimonials
            </Menu.Item>
            <Menu.Item onClick={() => scrollToSection("faqs")}>FAQs</Menu.Item>
            {/*  <Menu.Item onClick={() => navigate("/blog")}>Blog</Menu.Item> */}
            {/*             {token && (
              <Menu.Item
                onClick={() => navigate("/studio")}
                style={{ fontWeight: "bold" }}
              >
                Studio
                <Icon
                  name="arrow circle right"
                  color="blue"
                  style={{ paddingLeft: "5px" }}
                ></Icon>
              </Menu.Item>
            )} */}
            {user && user.stripe_subscription_status === "demo" && (
              <Menu.Item
                onClick={(event) => {
                  event.preventDefault(); // Prevent the default behavior
                  navigate("/account"); // Handle the navigation manually
                }}
              >
                <Button color="orange"> Make your photos now</Button>
              </Menu.Item>
            )}
          </React.Fragment>
        )}
        {parent === "studio" &&
          models &&
          models.length > 0 &&
          shouldShowMenuItems && (
            <React.Fragment>
              <Menu.Item
                onClick={(event) => {
                  event.preventDefault(); // Prevent the default behavior
                  navigate("/create"); // Handle the navigation manually
                }}
              >
                Make AI photos
              </Menu.Item>
              {mode === "demo" ? (
                <Menu.Item
                  onClick={(event) => {
                    event.preventDefault(); // Prevent the default behavior
                    navigate("/account"); // Handle the navigation manually
                  }}
                >
                  Train a new model
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={(event) => {
                    event.preventDefault(); // Prevent the default behavior
                    navigate("/train"); // Handle the navigation manually
                  }}
                >
                  Train a new model
                </Menu.Item>
              )}

              {user && user.stripe_subscription_status === "demo" && (
                <Menu.Item
                  onClick={(event) => {
                    event.preventDefault(); // Prevent the default behavior
                    navigate("/account"); // Handle the navigation manually
                  }}
                >
                  <Button color="orange">Make your photos now</Button>
                </Menu.Item>
              )}
            </React.Fragment>
          )}
        <Menu.Item position="right">
          {!token && parent === "home" && (
            <Button
              size="mini"
              basic
              compact
              color="blue"
              onClick={(e) => {
                e.preventDefault();
                navigateTo("/account?login=true");
              }}
            >
              Log in
            </Button>
          )}

          {token && parent === "home" && (
            <Dropdown
              icon={<Icon name="bars" size="large" />}
              direction="left"
              //className="icon"
              //style={{ backgroundColor: "#ecebf4" }}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="user"
                  color="grey"
                  text="Account"
                  onClick={() => navigateTo("/account")}
                />

                {token && (
                  <div>
                    {mode === "demo" ? (
                      <div>
                        <Dropdown.Item
                          icon="user"
                          color="grey"
                          text="Create account"
                          onClick={() => navigateTo("/account")}
                        />
                        <Dropdown.Item
                          icon="user"
                          color="grey"
                          text="Log in"
                          onClick={() => navigateTo("/account")}
                        />
                      </div>
                    ) : (
                      <div>
                        <Dropdown.Item
                          icon="sign-out"
                          color="grey"
                          text="Logout"
                          onClick={handleLogout}
                        />
                        {user &&
                          user.stripe_subscription_status !== "one-time" &&
                          user.stripe_subscription_status !== "demo" && (
                            <Dropdown.Item
                              icon="payment"
                              color="grey"
                              text="Billing"
                              onClick={handleOpenBillingPortal}
                            />
                          )}
                      </div>
                    )}
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {/* 
          {token ? (
            <Button
              size="mini"
              basic
              color="blue"
              compact
              onClick={handleLogout}
            >
              Logout
            </Button>
          ) : (
            <Button
              size="mini"
              basic
              compact
              color="blue"
              onClick={(e) => {
                e.preventDefault();
                navigateTo("/account");
              }}
            >
              Account
            </Button>
          )} */}
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default SiteHeader;

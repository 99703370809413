import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Segment,
  Button,
  Icon,
  Message,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Footer from "./Footer";
import SiteHeader from "./SiteHeader";
import { useNavigate } from "react-router-dom";

function Confirmation() {
  const [session, setSession] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [customerStatus, setCustomerStatus] = useState(null);
  const [modelName, setModelName] = useState(null);
  const [trainingStatus, setTrainingStatus] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    console.log("storedToken ", storedToken);
    if (storedToken) {
      setToken(storedToken);
      console.log("token found");
    } else {
      console.log("token not found");
    }
    const uploadedImages = localStorage.getItem("uploadedImages");
    if (uploadedImages) {
      console.log("uploaded images found");
      //navigate('/train');
    } else {
      console.log("no uploaded images");
    }
    const modelName = localStorage.getItem("modelName");
    if (modelName) {
      console.log("uploaded images found");
      setModelName(modelName);
      console.log("modelName", modelName);
    } else {
      console.log("no uploaded images");
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id");

    const fetchData = async () => {
      try {
        const apiResponse = await axios.get("/api/confirmation", {
          params: {
            session_id: sessionId,
          },
          headers: {
            Authorization: `${token}`,
          },
        });
        const { session } = apiResponse.data;
        setSession(session);
        console.log("stripe session: ", session);
      } catch (error) {
        console.error(error);
      }
    };

    if (sessionId && token) {
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    if (session) {
      const getCheckSubscription = () => {
        axios
          .get("/api/check-subscription", {
            headers: {
              Authorization: `${token}`,
            },
          })
          .then((response) => {
            const { customerStatus } = response.data;
            console.log(customerStatus);
            setCustomerStatus(customerStatus);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };

      getCheckSubscription();
    }
  }, [session, token]);

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const handleTrain = () => {
    setButtonClicked(true);
    const uploadedImages = JSON.parse(localStorage.getItem("uploadedImages"));
    //const uploadedImages = localStorage.getItem("uploadedImages");
    const modelName = localStorage.getItem("modelName");
    const selectedGender = localStorage.getItem("selectedGender");

    console.log("uploadedImages", uploadedImages);

    axios
      .post(
        "/api/create-model-onboard",
        {
          uploadedImages: uploadedImages,
          modelName: modelName,
          selectedGender: selectedGender,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
          responseType: "json", // Update the response type to 'json'
        }
      )
      .then((response) => {
        console.log("response:", response);
        const modelData = response.data;
        console.log(modelData); // Log the response data for debugging

        if (response.status === 200) {
          // If the response is successful, remove the localstorage items
          localStorage.removeItem("selectedGender");
          localStorage.removeItem("modelName");
          localStorage.removeItem("uploadedImages");
          setTrainingStatus(true);
          return modelData;
          // Handle the modelData or perform any other necessary actions
        } else if (response.status === 403) {
          // If the response status is 403, handle the access denied error
          //setSubscriptionStatus("lapsed");
          throw new Error("Access denied. Subscription required.");
        } else {
          // Handle other error scenarios
          throw new Error("An error occurred.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <SiteHeader handleLogout={handleLogout} token={token} />

      <Container style={{ marginTop: "5em" }}>
        <Segment textAlign="center">
          {!session && (
            <div>
              <Icon name="circle notched" loading></Icon> Please wait{" "}
            </div>
          )}

          {session &&
            !modelName &&
            (customerStatus === "subscribed" ||
              customerStatus === "one-time") && (
              <div>
                <h1>Welcome {session.customer_details.name}!</h1>
                <p>
                  {" "}
                  To create your AI photos, you'll need at least 15-20 photos.
                  Make sure to chose a wide variety of photos rather than many
                  photots from the same location. The AI needs variety to
                  generate good photos. Also make sure to have only you in the
                  photos, and to avoid any with hats or glasses.{" "}
                </p>
                <Button
                  onClick={() => navigate("/train")}
                  color="blue"
                  size="massive"
                >
                  Get started
                </Button>
              </div>
            )}

          {session &&
            !trainingStatus &&
            modelName && // if model name exists the customer came through onboarding and model is ready to start
            (customerStatus === "subscribed" ||
              customerStatus === "one-time") && (
              <div>
                <h1>Welcome {session.customer_details.name}!</h1>
                <p>
                  {" "}
                  Your model is ready to be created. Click the button below to
                  start.{" "}
                </p>

                <Button
                  onClick={handleTrain}
                  disabled={buttonClicked}
                  color="blue"
                >
                  {buttonClicked ? (
                    <div>
                      <Icon name="circle notched" loading></Icon> Please wait (up to 1 minute)
                    </div>
                  ) : (
                    "Create AI model"
                  )}
                </Button>
              </div>
            )}
          {session &&
            trainingStatus &&
            (customerStatus === "subscribed" ||
              customerStatus === "one-time") && (
              <div>
                <Icon name="check" color="green"></Icon>All done! Your model is
                now training and will take approximately 30 minutes to complete.{" "}
                <Message icon positive>
                  <Icon name="circle notched" loading />
                  <Message.Content>
                    <Message.Header>
                      Hold tight, we're training your model
                    </Message.Header>
                    <p>
                      <input
                        value="Check progress"
                        type="button"
                        className="link-button"
                        onClick={() => navigate("/create")}
                      />{" "}
                      on your model
                    </p>
                  </Message.Content>
                </Message>
              </div>
            )}
        </Segment>
      </Container>
      <Footer />
    </div>
  );
}

export default Confirmation;

import React, { useState } from "react";
import { Accordion, Icon, Divider } from "semantic-ui-react";

const HowItWorks = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion fluid>
      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        How does PhotoGen work?
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <p>
          PhotoGen lets you create an AI model with your own photos and then use
          that model to create new photos of you in different settings, styles
          and epressions. You can do this from your laptop or phone and save
          hundreds of dollars compared to a professional photo shoot.
        </p>
      </Accordion.Content>
      <Divider />
      <Accordion.Title
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        How do I cancel?
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <p>
          You can cancel your subscription any time via our customer portal.
          Just sign in and you'll see a "billing" link in the account menu.
          Click the link and you'll be taken to the portal where you can cancel
          or manage your subscription.
        </p>
      </Accordion.Content>
      <Divider />
      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        How long will it take to create an AI model?
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <p>
          It takes around 30 minutes to create the model. After that, you can
          come back to your model and use it immediately.
        </p>
      </Accordion.Content>
      <Divider />
      <Accordion.Title
        active={activeIndex === 4}
        index={4}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Will the photos look like me?
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 4}>
        <p>
          It depends on the number, quality and variety of the photos you upload. The
          better the photos, the more accurately the AI can understand and
          represent your unique characteristics. Upload at least 20 photos when creating a model.
        </p>
      </Accordion.Content>
      <Divider />
      <Accordion.Title
        active={activeIndex === 5}
        index={5}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        Are my photos secure?
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 5}>
        <p>
          Yes, we partner with industry leading providers to ensure our services
          are secure and safe.
        </p>
      </Accordion.Content>
      <Divider />
    </Accordion>
  );
};

export default HowItWorks;
